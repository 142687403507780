import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Row, Menu, Col, Dropdown, Button, Tag, Tooltip, Modal } from 'antd';
import { BlockOutlined, WarningOutlined, DownloadOutlined , CheckCircleOutlined, CloseCircleOutlined, SwapOutlined} from '@ant-design/icons';
import * as ExcelJS from 'exceljs';
import { debounce } from 'lodash';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import config from '../../../config/config';
//
import CommonSessionAddStudentDrawer from '../Drawers/CommonSessionAddStudentDrawer';
import CommonSearchBar from '../CommonSearchBar';
//props are: session, app,
//onEnrolmentRelease, onLaunch, onCheckGrade,
//onAddManualResult, onUpdateResult, onDeleteResult,
//onShippingInfoUpsert, onDeleteEnrolment,
//onSessionRelease, onCancelEnrolment
//onReplaceStudent
export default class CommonSessionStudentsForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: null,
      searchTerm: '',
      filteredEnrolments: props.session.enrolments || []
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch, 500); 
  }

  async generateReportXLSX() {
    if (this.props.session.enrolments.length < 1) {
      return;
    }
  
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
  
    // Generate XLSX header 
    ws.addRow(['Student','First Name', 'Last Name', 'Email', 'Phone', 'Affiliate', 'Preferred Language', 'Attendance', 'Attendance Notes', 'Result', 'Grade', 'Result Notes', 'Charged', 'Amount', 'Attempts']);
  
    // Generate XLSX rows
    this.props.session.enrolments.forEach((enrolments, index) => {
      const attendance = this.props.session.attendances.find((a) => a.userID === enrolments.userID && a.externalID === enrolments.externalID);
      const results = this.props.session.results.find((a) => a.userID === enrolments.userID && a.externalID === enrolments.externalID);
      ws.addRow([
        index + 1,  
        enrolments.firstName,
        enrolments.lastName,
        enrolments.email,
        enrolments.phoneNumber,
        enrolments.referralName,
        enrolments.preferredLanguage,
        attendance?.attendanceStatus ? attendance.attendanceStatus : '',
        attendance?.additionalNotes ? attendance.additionalNotes : '',
        results?.resultStatus ? results.resultStatus : '',
        results?.resultGrade ? results.resultGrade : '',
        results?.resultNotes ? results.resultNotes : '',
        (typeof enrolments.chargeCompleted == 'undefined' ? 'N/A' : (enrolments.chargeCompleted ? 'Charged' : 'Not Charged')),
        (typeof enrolments.amountCharged == 'undefined' ? 'N/A' : `$${Utils.toCurrencyFormat(enrolments.amountCharged)}`),
        enrolments.numAttempts || 'N/A',
      ]);
    });
  
    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, `session-students`, 'xlsx');
  }
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  handleStudentView(student) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.userDashboard, null, student.userID); }
  handleCancelEnrolment(props){
    Modal.confirm({ title: 'Attention!', cancelText: 'Back', okText: 'Confirm',
    content: `Are you sure you want to to cancel ${props.firstName} ${props.lastName}'s enrolment ?`,
    onOk: (() => this.props.onCancelEnrolment(props))
    })
  }
  handleDeleteEnrolment(props){
    Modal.confirm({ title: 'Attention!', cancelText: 'Back', okText: 'Confirm',
    content: `Are you sure you want to delete ${props.firstName} ${props.lastName}'s enrolment ? `,
    onOk: (() => this.props.onDeleteEnrolment(props))
    })
  }
  handleAddStudent(student) { this.SessionAddStudentDrawer.show(student); }
  handleDeleteResult(props, result, attendance){
    Modal.confirm({ title: 'Attention!', cancelText: 'Back', okText: 'Confirm',
    content: `Are you sure you want to delete ${props.firstName} ${props.lastName}'s results ? `,
    onOk: (() => this.props.onDeleteResult(props, result, attendance))
    })
  }
  async handleCharge(props){
    const cpID = props.externalID.split('::')[0];
    const certificationProcess = await this.props.app.api.certification.getByUserIDAndCertID(props.userID, cpID);
    if (certificationProcess.statusCode != 200 || !certificationProcess.body) {
      this.props.app.alertController.showAPIErrorAlert(null, certificationProcess);
      return;
    }
    const course = certificationProcess.body.courses.find(c => c.id == props.externalID);
    const licenseID = course.licenseID;
    const orderResp = await this.props.app.license.order.getOrderByID(licenseID);
    if (orderResp.statusCode != 200 || !orderResp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, orderResp);
      return;
    }
    if (orderResp.body.externalID != props.userID || (orderResp.body.isAssignment && orderResp.body.employerID)) {
      const orgID = orderResp.body.employerID || orderResp.body.externalID;
      Modal.confirm({
        title: 'Attention!',
        content: 'This course license was bought by an organization. Do you want to charge the student or the organization?',
        okText: 'Charge student',
        cancelText: 'Charge organization',
        maskClosable: true,
        closable: true,
        cancelButtonProps: {
          type: 'primary',
          onClick: (() => {
            Modal.destroyAll();
            this.props.onSessionCharge(props, certificationProcess.body, orgID);
          })
        },
        onOk: (() => this.props.onSessionCharge(props, certificationProcess.body))
      })
    } else {
      this.props.onSessionCharge(props, certificationProcess.body);
    }
  }

  handleSearch(searchTerm) {
    const { enrolments } = this.props.session;
    const filteredEnrolments = enrolments.filter(enrolment =>
      enrolment.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      enrolment.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      enrolment.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({ searchTerm, filteredEnrolments });
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const isSessionAvailable = this.props?.session?.state == Globals.Session_State.AVAILABLE;
    const canRemoveEnrolment = this.props?.session?.state != Globals.Session_State.COMPLETED && this.props?.session?.state != Globals.Session_State.LOCKED;
    const isSessionPendingReview = this.props?.session?.state == Globals.Session_State.PENDING_INSTRUCTOR || this.props?.session?.state == Globals.Session_State.PENDING_ADMIN;
    const showPreferredLanguage = this.props.app.sharedCache().getTenantConfig().settingsDisplayOptions?.showPreferredLanguageInSession;
    const columns = [
      {
        title: 'Name', key: 'name', sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        sorter: (a, b) => {
          return (`${a.firstName} ${a.lastName}`).localeCompare((`${b.firstName} ${b.lastName}`));
        },
        render: (props) => `${props.firstName} ${props.lastName}`
      },
      {
        title: 'Email', key: 'email',
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        sorter: (a, b) => { return (`${a.email}`).localeCompare((`${b.email}`)); },
        render: (props) => `${props.email}`
      },
      {
        title: 'Phone', key: 'phone',
        render: props => props.phoneNumber ? `${props.phoneNumber}` : '-',
      },
      {
        title: 'Affiliate', key: 'referralName',
        sortOrder: sortedInfo.columnKey === 'referralName' && sortedInfo.order,
        sorter: (a, b) => { return (`${a.referralName}`).localeCompare((`${b.referralName}`)); },
        render: props => props.referralName ? `${props.referralName}` : '-',
      }
    ];
    //showPreferredLanguage conditional splits columns array in half
    if(showPreferredLanguage) {
      columns.push({
        title: 'Preferred Language', key: 'preferredLanguage',
        sortOrder: sortedInfo.columnKey === 'preferredLanguage' && sortedInfo.order,
        sorter: (a, b) => (a.preferredLanguage || '').localeCompare(b.preferredLanguage || ''),
        render: (props) => props.preferredLanguage || '-',
      });
    }
    //rest of columns array
    columns.push(
      {
        title: 'Access Released', key: 'accessRelease',
        render: props => {
          if (props.accessRelease == Globals.SessionEnrolment_AccessRelease.RELEASED) return (<Tag color='green'>Released ({Utils.getDateAndTimeOnUIFormatByTimestamp(props.accessReleasedOn)})</Tag>);
          else if (props.accessRelease == Globals.SessionEnrolment_AccessRelease.LOCKED) return (<Tag color='vulcano'>Locked</Tag>);
          else if (props.accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO) {
            if (props.accessReleasedOn > 0) return (<Tag color='green'>Auto Released ({Utils.getDateAndTimeOnUIFormatByTimestamp(props.accessReleasedOn)})</Tag>);
            else return (<Tag color='geekblue'>Auto Release (pending)</Tag>);
          } return '-';
        },
        sorter: (a, b) => (a.accessRelease || '').localeCompare((b.accessRelease || '')),
        sortOrder: sortedInfo.columnKey === 'accessRelease' && sortedInfo.order
      },
      {
        title: 'Attendance', key: 'attendance',
        render: props => {
          const attendance = this.props.session?.attendances?.find((a) => a.userID == props.userID && a.externalID == props.externalID);
          if (!attendance) return '-';
          if (attendance.attendanceStatus == Globals.Attendance_Status.INCOMPLETE) return (<Tag color='red'>Incomplete</Tag>);
          else if (attendance.attendanceStatus == Globals.Attendance_Status.NO_SHOW) return (<Tag color='red'>No Show</Tag>);
          else if (attendance.attendanceStatus == Globals.Attendance_Status.ATTENDED) return (<Tag color='green'>Attended</Tag>);
          return '-';
        },
        sorter: (a, b) => {
          const attendanceA = this.props.session?.attendances?.find((_a) => _a.userID == a.userID && _a.externalID == a.externalID);
          const attendanceB = this.props.session?.attendances?.find((_a) => _a.userID == b.userID && _a.externalID == b.externalID);
          return (attendanceA?.attendanceStatus || '-').localeCompare((attendanceB?.attendanceStatus || '-'));
        },
        sortOrder: sortedInfo.columnKey === 'attendanceStatus' && sortedInfo.order
      },
      {
        title: 'Result', key: 'result',
        render: props => {
          const result = this.props.session?.results?.find((a) => a.userID == props.userID && a.externalID == props.externalID);
          if (!result) return '-';
          if (result.resultStatus == Globals.Result_Status.INCOMPLETE) return (<Tag color='red'>Incomplete</Tag>);
          else if (result.resultStatus == Globals.Result_Status.FAIL) return (<Tag color='red'>Failed ({result.resultGrade})</Tag>);
          else if (result.resultStatus == Globals.Result_Status.PASS) return (<Tag color='green'>Passed ({result.resultGrade})</Tag>);
          return '-';
        },
        sorter: (a, b) => {
          const resultA = this.props.session?.results?.find((r) => r.userID == a.userID && _a.externalID == a.externalID);
          const resultB = this.props.session?.results?.find((r) => r.userID == b.userID && _a.externalID == b.externalID);
          return (resultA?.resultStatus || '-').localeCompare((resultB?.resultStatus || '-'));
        },
        sortOrder: sortedInfo.columnKey === 'resultStatus' && sortedInfo.order
      },
      {
        title: 'Enrolled On', key: 'createdOn',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Post Charge', key: 'charged',
        sortOrder: sortedInfo.columnKey === 'charged' && sortedInfo.order,
        sorter: (a, b) => { return (`${a.chargeCompleted}`).localeCompare((`${b.chargeCompleted}`)); },
        render: (props) => (typeof props.chargeCompleted == 'undefined' ? '-' : (props.chargeCompleted ? <CheckCircleOutlined style={{ color: '#01d90f' }}/> : <CloseCircleOutlined style={{ color: '#f50000' }}/>))
      },
      {
        title: 'Amount', key: 'amount',
        sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order,
        sorter: (a, b) => { return (`${a.amountCharged}`).localeCompare((`${b.amountCharged}`)); },
        render: (props) => (typeof props.amountCharged == 'undefined' ? '-' : `$${Utils.toCurrencyFormat(props.amountCharged)}`)
      },
      {
        title: 'Attempts', key: 'attempts',
        sortOrder: sortedInfo.columnKey === 'attempts' && sortedInfo.order,
        sorter: (a, b) => { return (`${a.numAttempts}`).localeCompare((`${b.numAttempts}`)); },
        render: (props) => props.numAttempts || '-'
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          const attendance = this.props.session?.attendances?.find((a) => a.userID == props.userID && a.externalID == props.externalID);
          const result = this.props.session?.results?.find((a) => a.userID == props.userID && a.externalID == props.externalID);
          return (<span className='tableButtonContainer'>
            <Dropdown overlay={<Menu>
              {props.accessRelease == Globals.SessionEnrolment_AccessRelease.LOCKED &&
                <Menu.Item disabled={!isSessionAvailable} key="release" onClick={() => this.props.onEnrolmentRelease(props)}> Release Access </Menu.Item>}
              {this.props.session.onlineCourseID && (props.accessRelease == Globals.SessionEnrolment_AccessRelease.RELEASED || props.accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO) &&
                <Menu.Item disabled={!isSessionAvailable} key="launch" onClick={() => this.props.onLaunch(props)}> Launch </Menu.Item>}
              {this.props.session.onlineCourseID && (props.accessRelease == Globals.SessionEnrolment_AccessRelease.RELEASED || props.accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO) &&
                <Menu.Item disabled={!isSessionAvailable} key="checkGrade" onClick={() => this.props.onCheckGrade(props)}> Check Online Grade </Menu.Item>}
              {this.props.app.isAdmin() && <Menu.Item disabled={!isSessionAvailable} key="viewStudent" onClick={() => this.handleStudentView(props)}> View Student </Menu.Item>}
              {(props.accessRelease == Globals.SessionEnrolment_AccessRelease.RELEASED || props.accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO) &&
                <Menu.Item disabled={!canRemoveEnrolment} key="move" onClick={() => this.props.onSessionMoveOperation(props)}> Move to New Session </Menu.Item>}
              {props.chargeCompleted !== true &&
                <Menu.Item key="charge" onClick={() => this.handleCharge(props)}> Charge for course </Menu.Item>}
              {props.chargeCompleted === true &&
                <Menu.Item key="release" onClick={() => this.props.onSessionChargesAttempts(props)}> View charge attempts </Menu.Item>}
              <Menu.Divider />
              {!result && !attendance && <Menu.Item disabled={!isSessionAvailable && !isSessionPendingReview} key="addResult" onClick={() => this.props.onAddManualResult(props)}> Add Result </Menu.Item>}
              {(result || attendance) && <Menu.Item disabled={!isSessionAvailable && !isSessionPendingReview} key="updateResult" onClick={() => this.props.onUpdateResult(props, result, attendance)}> Update Result </Menu.Item>}
              {(result || attendance) && <Menu.Item disabled={!isSessionAvailable && !isSessionPendingReview} key="deleteResult" onClick={() => this.handleDeleteResult(props, result, attendance)}> Delete Result </Menu.Item>}
              {(!result || props.hasMaterial == Globals.Session_HasMaterial.INDIVIDUAL) && <Menu.Divider />}
              {props.hasMaterial == Globals.Session_HasMaterial.INDIVIDUAL && <Menu.Item disabled={!isSessionAvailable} key="addUpdateShippingInformation" onClick={this.props.onShippingInfoUpsert.bind(props)}> Add/Update Shipping Information </Menu.Item>}
              {!result && <Menu.Item disabled={!canRemoveEnrolment} key="cancelEnrolment" onClick={() => this.handleCancelEnrolment(props)}> <Tooltip title='Same license will be used to select a new session.'><BlockOutlined color='red' /> Cancel Enrolment</Tooltip> </Menu.Item>}
              {!result && <Menu.Item disabled={!canRemoveEnrolment} key="deleteEnrolment" onClick={() => this.handleDeleteEnrolment(props)}> <Tooltip title='New license will be required to select a new session.'><WarningOutlined color='red' /> Delete Enrolment</Tooltip> </Menu.Item>}
              {!result && <Menu.Item disabled={!isSessionAvailable} key="replaceStudent" onClick={() => this.props.onReplaceStudent(props)}> <Tooltip title='Student will be replaced on session, original license will be revoked.'><SwapOutlined color='red' /> Replace Student</Tooltip> </Menu.Item>}
            </Menu>}>
              <Button type='primary'>...</Button>
            </Dropdown>
          </span>);
        }
      },
    );

    const props = {
      rowKey: 'id',
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No students enrolled into this session yet!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (
      <>
        {this._renderSessionAddStudentDrawer()}
        {this.state.session?.releaseType == Globals.Session_ReleaseType.MANUAL &&
         this.state.session?.enrolments?.find((e) => e.accessRelease == Globals.SessionEnrolment_AccessRelease.LOCKED) &&
          <Row type='flex' justify='end'>
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.props.onSessionRelease}> Release Access for Everyone </Button>
            </Col>
          </Row>}
          <CommonSearchBar alwaysEnabled handleSearch={this.debouncedHandleSearch} />
          <Row type='flex' justify='end'>
            {this.props?.session?.state !== (Globals.Session_State.DRAFT && Globals.Session_State.COMPLETED && Globals.Session_State.LOCKED) &&
              <Button
                type="secondary"
                style={{ marginLeft: 4, marginBottom: '10px' }}
                onClick={this.handleAddStudent}
              >
                Add Student
              </Button>}
            <Button
              type="primary"
              style={{ marginLeft: 4, marginBottom: '10px' }}
              icon={<DownloadOutlined />}
              onClick={this.generateReportXLSX}
              disabled={this.props.session?.enrolments?.length < 1}
            >
              Export to xlsx
            </Button>
          </Row>
        <Table className="commonSessionStudentsTable" columns={columns} dataSource={this.state.filteredEnrolments || []} {...props} />
      </>
    );
  }

  //Private UI
  _renderSessionAddStudentDrawer() {
    return (
      <CommonSessionAddStudentDrawer {...Utils.propagateRef(this, 'SessionAddStudentDrawer')} app={this.props.app} session={this.props.session} />
    );
  }
}
