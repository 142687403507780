import React from 'react';
import autoBind from 'react-autobind';
import { Result, Button, Row, Col, message } from 'antd';
import { CloseCircleFilled, DownloadOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//props are: app, isVisible, certificationProcess, certificationSpecs, onUpdate
export default class CommonCertificationViewResultTabView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  //Actions
  handleDownloadCertificate() { this._downloadCurrentCertificate(); }
  handleCertificationRestart() { this._restartCurrentCertification(); }
  handleCertificationRenewal() {
    if (this.props.certificationSpecs?.renewal?.application) { //start application
      this._startRenewalApplicationForCurrentCertification();
    } else if (this.props.certificationSpecs?.renewal?.productID) {
      message.error('Renewal with payment but no application is current not implemented on the FE');
      //purchase modal here
    } else { //Simple renewal OP (system will check for expired required requirements)
      this._renewalCurrentCertification();
    }
  }
  handleContactSupport() { this.props.app.openContactModal(); }

  //UI
  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return (<></>);
    //
    const { certificationProcess } = this.props;
    const bodyByState = {
      [Globals.CertificationProcess_State.COMPLETED]: this._renderCompleted,
      [Globals.CertificationProcess_State.FAILED]: this._renderFailed,
      [Globals.CertificationProcess_State.EXPIRED]: this._renderExpired,
    };
    //
    return (
      <>
        <Row justify="center">
          <Col className="application-result">
            {bodyByState[certificationProcess.state]()}
          </Col>
        </Row>
      </>
    );
  }

  // Private methods
  _renderCompleted() {
    const { certificationProcess, certificationSpecs } = this.props;
    const extraActions = [];
    let isRetakeAllowed = false;
    if (certificationProcess.courses && Array.isArray(certificationProcess.courses) && certificationProcess.courses.length > 0) {
      const courseSpecs = this.props.app.sharedCache().getCourseByID(certificationProcess.courses[0].courseID);
      isRetakeAllowed = courseSpecs?.isRetakeAllowed;
    }

    //Add download certificate action if available
    if (this._isCertificateAvailable()) {
      extraActions.push(
        <Button type="primary" key="download" icon={<DownloadOutlined />} onClick={this.handleDownloadCertificate} loading={this.state.isLoading}>
          Download certificate
        </Button>
      );
    }

    //Determine expiration label
    let expirationLabel = '';
    const daysTilExpiration = this._calculateNumberOfDaysTilExpiration();
    if (daysTilExpiration !== null) {
      expirationLabel = ` This ${certificationSpecs.uiTerm} will expires on ${Utils.getDateOnUIFormatByTimestamp(certificationProcess.expiryDate)}.`;
      if (parseInt(daysTilExpiration) >= 0) {
        expirationLabel = ` This ${certificationSpecs.uiTerm} expires in ${parseInt(daysTilExpiration)} ${daysTilExpiration == 1 ? 'day' : 'days'}.`;
      } else if (parseInt(daysTilExpiration) < 0) return this._renderExpired(); //API havent changed the state yet?
    }

    //Determine if is about to expire and allow renewal
    let renewalAllowed = (daysTilExpiration !== null && parseInt(daysTilExpiration) >= 0 && this._isRenewalBeforeExpirationAllowed());
    if (renewalAllowed) {
      expirationLabel += ` You are allowed to start your renewal now if desired.`;
      //Add renewal action
      extraActions.push(
        <Button type="primary" key="renew" icon={<ReloadOutlined />} onClick={this.handleCertificationRenewal} loading={this.state.isLoading} disabled={this._isRenewalInProgress()}>
          Renew now
        </Button>
      );
    }

    let retakeMessage = '';
    if (isRetakeAllowed) {
      retakeMessage = `If you would like to re-take this course, please click on the “Course” tab, then click on “Retake” to repurchase it and take it again.`;
    }

    return (
      <Result status={renewalAllowed ? 'warning' : 'success'} title={`${Utils.camelizeString(certificationSpecs.uiTerm)} completed!`}
        subTitle={`You have successfully completed ${certificationProcess.certificationDescription} ${certificationSpecs.uiTerm}.${expirationLabel}
        ${retakeMessage}`}
        extra={extraActions}
      />
    );
  }

  _renderFailed() {
    const { certificationProcess, certificationSpecs } = this.props;
    return (
      <Result status="error" title={`${Utils.camelizeString(certificationSpecs.uiTerm)} failed`}
        subTitle={`You have failed the ${certificationProcess.certificationDescription}. You will be required to start the process again.`}
        extra={[
          <Button type="primary" key="download" icon={<ReloadOutlined />} onClick={this.handleCertificationRestart} loading={this.state.isLoading}>
            Restart
          </Button>,
        ]}
      />
    );
  }

  _renderExpired() {
    const { certificationProcess, certificationSpecs } = this.props;
    const extraActions = [];

    //Add download certificate action if available
    if (this._isCertificateAvailable()) {
      extraActions.push(
        <Button type="primary" key="download" icon={<DownloadOutlined />} onClick={this.handleDownloadCertificate} loading={this.state.isLoading}>
          Download certificate
        </Button>
      );
    }
    //Renewal action is always available
    extraActions.push(
      <Button type="primary" key="renew" icon={<ReloadOutlined />} onClick={this.handleCertificationRenewal} loading={this.state.isLoading} disabled={this._isRenewalInProgress()}>
        Renew now
      </Button>
    );

    return (
      <>
        <Result status="warning" extra={extraActions}
          title={
            <>{Utils.camelizeString(certificationSpecs.uiTerm)} expired on <strong>{Utils.getDateOnUIFormatByTimestamp(certificationProcess.expiryDate)}</strong></>
          }
          subTitle={`
            ${certificationProcess.certificationDescription} has expired on ${Utils.getDateOnUIFormatByTimestamp(certificationProcess.expiryDate)} and you are required to renew it to continue certificated.
          `}/>
        Any problems?
        <Button onClick={this.handleContactSupport} loading={this.state.isLoading} style={{marginLeft: 10}}>Contact Support</Button>
      </>
    );
  }

  /* Private Helpers */
  _isCertificateAvailable() {
    const { certificationSpecs } = this.props;
    return ((certificationSpecs.certificateType == Globals.CertificationProcess_CertificationPrintType.CERT) ||
            (certificationSpecs.certificateType == Globals.CertificationProcess_CertificationPrintType.CARD));
  }
  _calculateNumberOfDaysTilExpiration() {
    const { certificationProcess } = this.props;
    if (certificationProcess.expiryDate > 0) {
      const time = new Date(certificationProcess.expiryDate);
      let daysDiff = (time.getTime() - Date.now()) / (1000 * 3600 * 24);
      if (parseInt(daysDiff) >= 0) return (daysDiff + 1);
      else if (parseInt(daysDiff) < 0) return daysDiff;
    } return null;
  }
  _isRenewalBeforeExpirationAllowed() {
    const { certificationProcess, certificationSpecs } = this.props;
    return CommonCertificationViewResultTabView.isRenewalBeforeExpirationAllowed(certificationSpecs, certificationProcess);
  }
  _isRenewalInProgress() {
    const { certificationProcess, certificationSpecs } = this.props;
    return (certificationSpecs.renewal?.application && certificationProcess.applications?.find((a) => a.type == Globals.ApplicationTypes.RENEWAL && (!a.reviewAcknowledge || a.reviewAcknowledge < 0)))
  }
  // Private API
  async _downloadCurrentCertificate() {
    this.startLoading();
    const resp = await this.props.app.api.certification.getPrintingCertificate(this.props.certificationProcess.userID, this.props.certificationProcess.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Download Initiated!');
      Utils.downloadBlob(resp.body, 'certificate', 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _toogleCertificationWaiveStatus(waived, optionalComments) {
    this.startLoading();
    const resp = await this.props.app.api.certification.toogleWaiveStatus(this.props.certificationProcess.userID, this.props.certificationProcess.id, optionalComments, waived);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Waived status updated with success!');
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _restartCurrentCertification() {
    this.startLoading();
    const resp = await this.props.app.api.certification.restartCertificationByUserIDAndCertID(this.props.certificationProcess.userID, this.props.certificationProcess.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success(`${Utils.capitalizeString(this.props.certificationSpecs.uiTerm)} restarted with success!`);
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _renewalCurrentCertification() {
    this.startLoading();
    const resp = await this.props.app.api.certification.renewCertificationByUserIDAndCertID(this.props.certificationProcess.userID, this.props.certificationProcess.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success(`${Utils.capitalizeString(this.props.certificationSpecs.uiTerm)} renewal started with success!`);
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _startRenewalApplicationForCurrentCertification() {
    this.startLoading();
    const resp = await this.props.app.api.application.start(this.props.certificationProcess.userID, this.props.certificationProcess.id, Globals.ApplicationTypes.RENEWAL);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success(`${Utils.capitalizeString(this.props.certificationSpecs.uiTerm)} renewal started with success!\n You\'re required to apply for your renewal!`);
      await this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
}
//Static interface
CommonCertificationViewResultTabView.isRenewalBeforeExpirationAllowed = function(certSpecs, certProc) {
  if (certSpecs.allowRenewalBeforeInMonths && certSpecs.allowRenewalBeforeInMonths != -1) {
    const time = Utils.timestampBeforeMonthsFromDate(certSpecs.allowRenewalBeforeInMonths, new Date(certProc.expiryDate));
    return !!(time <= Date.now());
  } else if (certSpecs.allowRenewalBefore && certSpecs.allowRenewalBefore != -1) {
    const time = Utils.timestampBeforeYearsFromDate(certSpecs.allowRenewalBefore, new Date(certProc.expiryDate));
    return !!(time <= Date.now());
  } return false;
}
CommonCertificationViewResultTabView.GetTabTitleView = function (certSpecs, certProc) {
  if (!certSpecs || !certProc) return '';
  if (certProc.state == Globals.CertificationProcess_State.COMPLETED) {
    if (CommonCertificationViewResultTabView.isRenewalBeforeExpirationAllowed(certSpecs, certProc)) {
      return <>{Utils.camelizeString(certSpecs.uiTerm)} Completed <WarningOutlined style={{ marginLeft: 7, fontSize: 16 }} /></>;
    } else {
      return <>{Utils.camelizeString(certSpecs.uiTerm)} Completed</>;
    }
  }
  if (certProc.state == Globals.CertificationProcess_State.EXPIRED) {
    return <>{Utils.camelizeString(certSpecs.uiTerm)} Expired <WarningOutlined style={{ marginLeft: 7, fontSize: 16 }} /></>;
  }
  if (certProc.state == Globals.CertificationProcess_State.FAILED) {
    return <>{Utils.camelizeString(certSpecs.uiTerm)} Failed <CloseCircleFilled style={{ marginLeft: 7, fontSize: 16 }} /></>;
  }
  return '';
}

