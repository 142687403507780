import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
//
import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';
//
import CourseTabWrapper from './CourseTabWrapper';
import CommonResultCard from '../../../commonComponents/CommonResultCard';

//props are: app, currentCourse
export default class CourseTabResultsTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return (<></>);
    //
    let courseSpecs =  { passUiTerm: "Passed", failUiTerm: "Failed", revokedUiTerm: "Revoked" };
    if (this.props.currentCourse) {
      courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.currentCourse.courseID);
    }
    console.log('*-*- rendering attemps courseSpecs', courseSpecs);

    const columns = [
      {
        title: 'Status', dataIndex: 'state',
        render: state => {
          const passed = state == Globals.Course_State.PASS;
          const revoked = state == Globals.Course_State.REVOKED;
          const statusText = passed ? (courseSpecs.passUiTerm || 'Passed') : revoked ? 'Revoked' : (courseSpecs.failUiTerm || 'Failed');

          return (
            <div className="value">
              <div style={{ display: 'inline-block' }} className={`status-dot ${passed ? 'green' : 'red'}`} />
              <span> {statusText} </span>
            </div>
          );
        }
      },
      { title: 'Grade', render: props => parseInt(props.courseResult?.resultGrade || props.courseResultV1?.grade || 0) },
      { title: 'Result Date', render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props.courseResult?.resultDate || props.courseResultV1?.resultDate), },
      {
        title: 'Actions', width: '10%', key: 'Actions',
        render: props => {
          console.log('*-*- rendering attemps props', props, courseSpecs);
          return props.courseResult?.resultStatus === 'PASS' && courseSpecs.courseCertificateType  === Globals.CertificationProcess_CertificationPrintType.CERT ? (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title='Download a copy of the certificate'>
                <Button variant="none" icon={<DownloadOutlined />} shape="circle" onClick={this.props.onDownload}/>
              </Tooltip>
            </span>
          ) : null;
        }
      }
    ];

    const filteredRows = this.props.resultsHistory.filter(
      row => (row.state == Globals.Course_State.PASS || row.state == Globals.Course_State.FAIL || row.state == Globals.Course_State.REVOKED)
    );

    return super.render(
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filteredRows}
        expandable={{ expandedRowRender: this._renderExpandedRow }}
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
        scroll={{ x: true }}
      />
    );
  }

  _renderExpandedRow(row) {
    return (
      <CommonResultCard app={this.props.app} currentCourse={row}/>
    );
  }
}
