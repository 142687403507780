import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Form, Input, message } from 'antd';

import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
import CommonLoadingView from '../commonComponents/CommonLoadingView';

import Globals from '../../config/Globals';

import AdminReviewApplicationHeader from './AdminReviewApplicationView/AdminReviewApplicationHeader';
import AdminReviewApplicationRequirementsSection from './AdminReviewApplicationView/AdminReviewApplicationRequirementsSection';
import CommonCertificationViewUserHeader from '../commonSubviews/CommonCertificationView/CommonCertificationViewUserHeader';
import config from '../../config/config';

export default class AdminReviewApplicationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.certProcID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    this.userID = this.props.match.params[Globals.URL_Path_ID2_Placeholder];
    this.applicationType = this.props.match.params[Globals.URL_Path_ID3_Placeholder]?.toUpperCase();

    this.applicationReviewStatusTypes = {
      approve: 'approve',
      reject: 'reject',
      preApproval: 'preApproval',
    };

    this.state = {
      isLoading: false,
      isApplicationReviewModalVisible: false,
      applicationReviewStatus: null, // approve, reject, pre-approval
      user: null,
      certificationSpecs: null,
      certificationProcess: null,
      applicationProcess: null,
      applicationSpecs: null,
      sections: {},
      sectionsItemsStatuses: null,
    };
  }

  componentDidMount() {
    this._fetch();
  }

  handleApproveApplication(item) {
    this.setState({
      isApplicationReviewModalVisible: true,
      applicationReviewStatus: this.applicationReviewStatusTypes.approve,
    }, () => {
      this.applicationReviewModalForm.setFieldsValue({
        additionalNotes: this.state.applicationProcess?.reviewNotes || null,
      });
    });
  }

  handleRejectApplication(item) {
    this.setState({
      isApplicationReviewModalVisible: true,
      applicationReviewStatus: this.applicationReviewStatusTypes.reject,
    }, () => {
      this.applicationReviewModalForm.setFieldsValue({
        additionalNotes: this.state.applicationProcess?.reviewNotes || null,
      });
    });
  }

  handlePreApproveApplication(item) {
    this.setState({
      isApplicationReviewModalVisible: true,
      applicationReviewStatus: this.applicationReviewStatusTypes.preApproval,
    }, () => {
      this.applicationReviewModalForm.setFieldsValue({
        additionalNotes: this.state.applicationProcess?.reviewNotes || null,
      });
    });
  }

  handleApproveSubmissionItem(itemDetails, currentApplicationItem) {
    const isApproved = true;

    this._submitApplicationItemReview(
      isApproved,
      currentApplicationItem.sourceID,
      currentApplicationItem.timestamp,
      itemDetails,
    );
  }

  handleRejectSubmissionItem(itemDetails, currentApplicationItem) {
    const isApproved = false;

    this._submitApplicationItemReview(
      isApproved,
      currentApplicationItem.sourceID,
      currentApplicationItem.timestamp,
      itemDetails,
    );
  }

  async handleSubmitApplicationReview() {
    const formData = await this.applicationReviewModalForm.validateFields();

    if (formData) {
      const APICall = {
        [this.applicationReviewStatusTypes.approve]: () => {
          this._submitApplicationReview(true, formData.additionalNotes);
        },
        [this.applicationReviewStatusTypes.reject]: () => {
          this._submitApplicationReview(false, formData.additionalNotes);
        },
        [this.applicationReviewStatusTypes.preApproval]: () => {
          this._submitApplicationPreApproval(formData.additionalNotes);
        },
      };

      if (APICall[this.state.applicationReviewStatus]) {
        APICall[this.state.applicationReviewStatus]();
      }
    }
  }

  handleCloseApplicationReviewModal() {
    this.applicationReviewModalForm.resetFields();

    this.setState({
      isApplicationReviewModalVisible: false,
      applicationReviewStatus: null,
    });
  }

  handlePreview(currentApplicationItem, file) {
    this._preview(
      this.state.user.id,
      this.state.certificationProcess.id,
      currentApplicationItem.sourceID,
      file,
    );
  }

  handleDownload(currentApplicationItem, file) {
    this._download(
      this.state.user.id,
      this.state.certificationProcess.id,
      currentApplicationItem.sourceID,
      file,
    );
  }

  handleDownloadApplication() {
    this._downloadApplication(this.state.certificationProcess, this.state.certificationSpecs, this.state.applicationProcess.type);
  }

  handleToogleWaiveStatus(waived, optionalComments) { this._toogleCertificationWaiveStatus(waived, optionalComments); }

  render() {
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} />

        <CommonCertificationViewUserHeader
          app={this.props.app} user={this.state.user}
          certificationSpecs={this.state.certificationSpecs}
          certificationProcess={this.state.certificationProcess}
          onWaiveToogle={this.handleToogleWaiveStatus}
          showApplicationIcon
          showBackButton={true}
        />

        <AdminReviewApplicationHeader
          app={this.props.app}
          certificationProcess={this.state.certificationProcess}
          onApprove={this.handleApproveApplication}
          onReject={this.handleRejectApplication}
          onPreApprove={this.handlePreApproveApplication}
          onDownloadApplication={this.handleDownloadApplication}
          sections={this.state.sections}
          sectionsItemsStatuses={this.state.sectionsItemsStatuses}
          applicationType={this.applicationType}
          applicationProcess={this.state.applicationProcess}
        />

        {Object.keys(this.state.sections).length > 0 && (
          <AdminReviewApplicationRequirementsSection
            sections={this.state.sections}
            certificationProcess={this.state.certificationProcess}
            applicationProcess={this.state.applicationProcess}
            onPreview={this.handlePreview}
            onDownload={this.handleDownload}
            onApprove={this.handleApproveSubmissionItem}
            onReject={this.handleRejectSubmissionItem}
            sectionsItemsStatuses={this.state.sectionsItemsStatuses}
            getSectionItemData={this._getSectionItemData}
            certificationSpecs={this.state.certificationSpecs}
          />
        )}

        {this._renderReviewModal()}
      </>
    );
  }

  // Private methods
  _renderReviewModal() {
    const modalOkButtonText = {
      [this.applicationReviewStatusTypes.approve]: 'Approve',
      [this.applicationReviewStatusTypes.reject]: 'Reject',
      [this.applicationReviewStatusTypes.preApproval]: 'Submit',
    };

    return (
      <Modal
        title="Submit Review"
        visible={this.state.isApplicationReviewModalVisible}
        okText={modalOkButtonText[this.state.applicationReviewStatus]}
        onOk={this.handleSubmitApplicationReview}
        onCancel={this.handleCloseApplicationReviewModal}
      >
        <Form layout="vertical" style={{ width: 400 }} {...Utils.propagateRef(this, 'applicationReviewModalForm')}>
          <Form.Item label="Additional notes" name="additionalNotes">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  _getSectionsItemsStatuses(sections, applicationProcess, certificationProcess) {
    const sectionsStatuses = {};

    Object.entries(sections).forEach(([sectionKey, section]) => {
      section.items.forEach((sectionItem) => {
        const { status } = this._getSectionItemData(sectionItem, applicationProcess, certificationProcess);

        if (!sectionsStatuses[sectionKey]) {
          sectionsStatuses[sectionKey] = {
            [Globals.ApplicationItemsStatus.DEFAULT]: [],
            [Globals.ApplicationItemsStatus.APPROVED]: [],
            [Globals.ApplicationItemsStatus.REJECTED]: [],
          };
        }

        sectionsStatuses[sectionKey][status].push(sectionItem);
      });
    });

    return sectionsStatuses;
  }

  _getFilledOptions(submissionItemsIDs, section, optionsKey) {
    let filledOptions = [];

    const options = section?.[optionsKey];

    options.forEach((option) => {
      const { acceptedProviders } = option;

      const providersIDs = acceptedProviders.map((provider) => provider.id);

      const isInSubmittedItems = providersIDs.find((providerID) => submissionItemsIDs?.includes(providerID));
      if (isInSubmittedItems) {
        const providerObject = acceptedProviders.find((provider) => provider.id == isInSubmittedItems);

        if (providerObject?.type != Globals.Providers_Types.INTERNAL) {
          filledOptions.push(option);
        }
      }
    });

    return filledOptions.sort((a, b) => a.order - b.order);
  }

  _getSectionItemData(sectionItem, appProc = null, certProc = null) {
    let {
      applicationProcess,
      certificationProcess,
    } = this.state;

    if (appProc) {
      applicationProcess = appProc;
    }

    if (certProc) {
      certificationProcess = certProc;
    }

    let submittedItem = null;
    let currentApplicationItem = null;
    let status = Globals.ApplicationItemsStatus.DEFAULT;

    let currentProvider = sectionItem.acceptedProviders.find(
      (provider) => applicationProcess.submissionItems?.find(
        subItem => subItem.id == provider.id
      )
    );

    if (currentProvider) {
      submittedItem = applicationProcess.submissionItems?.find(
        (subItem) => sectionItem.acceptedProviders.find(
          provider => subItem.id == provider.id
        )
      );

      if (submittedItem) {
        currentApplicationItem = certificationProcess.applicationsItems?.find(
          appItem => appItem.sourceID == submittedItem.id && appItem.timestamp == submittedItem.version
        );
      }
    }

    if (currentApplicationItem && currentApplicationItem?.approvedBy) {
      status = Globals.ApplicationItemsStatus.APPROVED;
    } else if (currentApplicationItem && currentApplicationItem?.rejectedBy) {
      status = Globals.ApplicationItemsStatus.REJECTED;
    }

    return { currentProvider, submittedItem, currentApplicationItem, status };
  }

  // API Calls
  async _fetch() {
    this.startLoading();

    const [userResp, certProcessResp] = await Promise.all([
      this.props.app.api.user.getByID(this.userID),
      this.props.app.api.certification.getByUserIDAndCertID(this.userID, this.certProcID),
    ]);
    if (!this._isMounted) return;

    if (userResp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, userResp);
      return;
    }

    if (certProcessResp.statusCode == 200) {
      const certificationProcess = certProcessResp.body;

      const applicationProcess = certificationProcess.applications?.find(
        (applicationObject) => applicationObject.type?.toLowerCase() == this.applicationType?.toLowerCase()
      );
      const certificationSpecs = this.props.app.sharedCache().getCertificationByID(certificationProcess.certificationID);
      const applicationSpecs = (
        this.applicationType?.toLowerCase() == Globals.ApplicationTypes.RENEWAL.toLowerCase()
          ? certificationSpecs.renewal?.application
          : certificationSpecs.application
      );

      const submissionItemsIDs = applicationProcess?.submissionItems?.map((submissionItem) => submissionItem.id);

      const {
        equivalenciesSection, requirementsSection, electiveRequirementsSection, customRequirementsSections,
      } = applicationSpecs;

      const sections = {
        equivalenciesSection: {
          name: equivalenciesSection.name,
          description: equivalenciesSection.sectionDescription,
          items: this._getFilledOptions(submissionItemsIDs, equivalenciesSection, 'options'),
        },
        requirementsSection: {
          name: requirementsSection.name,
          description: requirementsSection.sectionDescription,
          items: this._getFilledOptions(submissionItemsIDs, requirementsSection, 'requirements'),
        },
        electiveRequirementsSection: {
          name: electiveRequirementsSection.name,
          description: electiveRequirementsSection.sectionDescription,
          items: this._getFilledOptions(submissionItemsIDs, electiveRequirementsSection, 'requirements'),
        },
      };

      if (customRequirementsSections) {
        customRequirementsSections.forEach((section) => {
          sections[section.name] = {
            name: section.name,
            description: section.sectionDescription,
            items: this._getFilledOptions(submissionItemsIDs, section, 'requirements'),
          };
        });
      }

      Object.entries(sections).forEach(([key, value]) => {
        if (value.items.length < 1) {
          delete sections[key];
        }
      });

      const sectionsItemsStatuses = this._getSectionsItemsStatuses(sections, applicationProcess, certificationProcess);

      this.setState({
        user: userResp.body,
        certificationSpecs,
        certificationProcess,
        applicationProcess,
        applicationSpecs,
        sections,
        sectionsItemsStatuses,
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, certProcessResp);
    }

    this.stopLoading();
  }

  async _preview(userID, certProcID, sourceID, file) {
    const { extension } = Utils.splitFilenameAndExtension(file.fileName);
    const hasPreview = ['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());

    if (!hasPreview) {
      this._download(userID, certProcID, sourceID, file);
      return;
    }

    this.startLoading();
    const resp = await this.props.app.api.applicationItemFile.getSignURL(userID, certProcID, sourceID, file.fileID)
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
      this.stopLoading();
    }
  }

  async _download(userID, certProcID, sourceID, file) {
    this.startLoading();

    const resp = await this.props.app.api.applicationItemFile.download(userID, certProcID, sourceID, file.fileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(resp.body, `${filename || file.fileName}`, extension || Utils.getFileExtensionFromMimeType(resp.body.type));
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.stopLoading();
  }

  async _submitApplicationReview(approved, additionalNotes) {
    this.startLoading();

    const resp = await this.props.app.api.pendingApplications.reviewPendingApplication(
      this.state.user.id,
      this.state.certificationProcess.id,
      this.state.applicationProcess.type,
      { approved, additionalNotes },
    );
    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      await this.props.app.reloadPendingApplications();
      message.success('Application review successfully submitted!');
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.pendingApplications);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  async _submitApplicationPreApproval(additionalNotes) {
    this.startLoading();

    const resp = await this.props.app.api.pendingApplications.submitPreApprovalReview(
      this.state.user.id,
      this.state.certificationProcess.id,
      this.state.applicationProcess.type,
      { additionalNotes: additionalNotes || '' },
    );
    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      await this.props.app.reloadPendingApplications();
      message.success('Pre approval review successfully submitted!');
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.pendingApplications);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  async _submitApplicationItemReview(approved, sourceID, itemVersion, itemDetails) {
    this.startLoading();

    const body = {
      approved,
      additionalNotes: itemDetails.additionalNotes || '',
      ...(itemDetails.issuedOn ? { issuedOn: Utils.getTimestampFromMoment(itemDetails.issuedOn) } : {}),
      ...(itemDetails.expiresOn ? { expiresOn: Utils.getTimestampFromMoment(itemDetails.expiresOn) } : {}),
    };

    const resp = await this.props.app.api.pendingApplications.reviewPendingApplicationItem(
      this.state.user.id,
      this.state.certificationProcess.id,
      sourceID,
      itemVersion,
      body,
    );
    if (!this._isMounted) return;

    if (resp?.statusCode == 200) {
      message.success('Application review successfully submitted!');
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  async _downloadApplication(certificationProcess, certificationSpecs, applicationType) {
    this.startLoading();
    try {
      await this.props.app.api.newApplicationDownload(certificationProcess, [], certificationSpecs, applicationType);
    } catch (e) {
      this.props.app.alertController.showErrorAlert('Could not download application!', e.message);
    }
    if (!this._isMounted) return;
    this.stopLoading();
  }

  async _toogleCertificationWaiveStatus(waived, optionalComments) {
    this.startLoading();
    const resp = await this.props.app.api.certification.toogleWaiveStatus(
      this.state.user.id, this.state.certificationProcess.id,
      optionalComments, waived
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Waived status updated with success!');
      await this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
