import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Tag, Button, DatePicker, Select, Modal, message, Row, Col } from 'antd';
import { BookOutlined, UserSwitchOutlined, ToolOutlined, CloseCircleOutlined, FileDoneOutlined, FileAddOutlined, DeleteOutlined, DownloadOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonSearchBar from '../commonComponents/CommonSearchBar';
import CommonSetPasswordModal from '../commonComponents/CommonSetPasswordModal';
import OrganizationMemberProfileModal from '../commonComponents/Modals/OrganizationMemberProfileModal';
import CommonOrganizationSelectionModal from '../commonComponents/OrganizationSelection/CommonOrganizationSelectionModal';
import CommonAssignLicenseDrawer from '../commonComponents/Drawers/CommonAssignLicenseDrawer';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonSearchUsersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, isSetPassModalVisible: false, firstLoad: true,
      users: [], selectedEmployer: null, isAssignLicenseDrawerVisible: false,
      selectedUser: null,
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
  }

  //API
  async fetchData() {
    this.setState({ users: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.user.searchUsersByTerm(
      this.state.searchObj.term, this._getSearchFilter(),
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = resp.body.users.map(user => user._source);
      this.setState({ users, total: resp.body.total, firstLoad: true, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  //Actions
    //Main actions
  async handleExportXLSX() {
    this.startLoading();
    //Org member view does export on a unique way
    if (!this.props.isOrgMembersView) {
      //request
      const resp = await this.props.app.api.user.printSearchUsersByTerm(
        this.state.searchObj.term, this._getSearchFilter(),
      );
      if (!this._isMounted) return; //Important, check if is mounted
      if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'users', 'xlsx');
      else this.props.app.alertController.showAPIErrorAlert(null, resp);
    } else {
      // Starts XLSX
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('Sheet1');
      // Generate XLSX header
      ws.addRow([ 'Name', 'Referred by', 'Registered date', 'Certificate number', 'Completion date', 'Status', 'ITA #' ]);
      // Generate XLSX rows
      this.state.users.forEach(user => {
        ws.addRow([
          user.fullName, user.referredByName || '-', user.createdOn ? Utils.getDateOnUIFormatByTimestamp(user.createdOn) : 'N/A',
          !user.memberNumber ? '-' : Object.entries(user.memberNumber).map(([certID, number]) => (
            `${number} ${this._getCertificationName(certID)}`
          )).join(', '),
          !user.certificationDate ? 'N/A' : Object.entries(user.certificationDate).map(([certID, timestamp]) => (
            `${Utils.getDateOnUIFormatByTimestamp(timestamp)} ${this._getCertificationName(certID)}`
          )).join(', '),
          !user.certificationDate ? '' : Object.entries(user.certificationDate).map(([certID, timestamp]) => {
            let description = this._getStatusDescription(user, certID, timestamp);
            return (
              `${description} ${this._getCertificationName(certID)}`
            )
          }).join(', '),
          user.itaNumber,
        ]);
      });
      const buffer = await wb.xlsx.writeBuffer();
      Utils.downloadArrayBuffer(buffer, `students`, 'xlsx');
    }
    this.stopLoading();
  }
  handleSearch(term) {
    this.setState({ searchObj: { term }, sortedInfo: null, currentPage: 1 }, () => {
      this.fetchData();
    });
  }
    //Table
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => {
      this.fetchData();
    });
  }
  onRowSelection(val) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.userDashboard, {}, val.id); }
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => {
      this.fetchData();
    })
  }
    //Custom filter
  handleChangeFilter = (type) => (value) => {
    // validating by type so we can set multiple filter types with only one state change
    // and encapsulate filters change logic in only one method
    // this is useful today only for registered date range
    const filter = !Array.isArray(value) && typeof value == 'object' ? value : { [type]: value };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...filter },
    }));
  };
  handleChangeStatusFilter = (value) => {
    if (value == 'ALL') this.handleChangeFilter('certification')('ALL');
    this.handleChangeFilter('status')(value);
  };
  handleSigninDateChange(date) {
    let [signinDate1, signinDate2] = (date || []);
    signinDate1 = (signinDate1 ? signinDate1.format('YYYY-MM-DD') : null);
    signinDate2 = (signinDate2 ? signinDate2.format('YYYY-MM-DD') : null);

    this.setState({ momentRange: date }); // used to populate when state is cached
    this.handleChangeFilter(null)({ signinDate1, signinDate2 });
  }
  handleExpiryDateChange(date) {
    let [certExpDate1, certExpDate2] = (date || []);
    certExpDate1 = (certExpDate1 ? certExpDate1.format('YYYY-MM-DD') : null);
    certExpDate2 = (certExpDate2 ? certExpDate2.format('YYYY-MM-DD') : null);

    this.setState({ expiryRange: date }); // used to populate when state is cached
    this.handleChangeFilter(null)({ certExpDate1, certExpDate2 });
  }
  // filters
  handleSearchEmployer() { this.companySelectionModal.show(); }
  handleDeselectEmployer() { this.setState({ selectedEmployer: null, sortedInfo: null, currentPage: 1 }, this.fetchData); }
  handleEmployerSelected(org) { this.setState({ selectedEmployer: org, sortedInfo: null, currentPage: 1 }, this.fetchData); }
    //Modal
  handleSetPassClose() { this.setState({ isSetPassModalVisible: false }); }
    //Table actions
  handleRemoveMember(record) {
    Modal.confirm({ title: 'Attention!', cancelText: 'Cancel', okText: 'Remove',
      content: `Are you sure you want to remove ${record.fullName} from your organization?`,
      onOk: () => this._removeMember(record),
    });
  }
  handleUserPasswordSet(userObj) {
    this.setState({ isSetPassModalVisible: true }, () => { this.setPassmodal.loadInfo(userObj.id); });
  }
  handleUserLicenses(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.commonLicenses, null, userObj.id); }
  handleUserCourses(userObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.commonCourses, null, userObj.id); }
  handleRefreshOrgMembership(record) { this._refreshOrgMembership(record); }
  handleEditOrgMemberProfile(memberObj) {
    if (this.organizationMemberProfileModal) this.organizationMemberProfileModal.show(this._getScopedOrgID(), memberObj.id);
  }
  handleAssignLicense(record) {
    this.setState({
      isAssignLicenseDrawerVisible: true,
      selectedUser: { record }
    })
  };
  handleAssignLicenseDrawerClose() { this.setState({ isAssignLicenseDrawerVisible: false }); }
  //UI
  render() {
    const props = { rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange, scroll: { x: true },
                    locale: {emptyText: (this.state.firstLoad ? 'Search users' : 'No users found!')},
                    pagination: {
                      pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: ['bottomCenter'],
                      total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage
                    }
                  };
    return (
      <>
        {this._renderSetPasswordModal()}
        {this._renderOrganizationMemberProfileModal()}
        {this._renderOrganizationSelectionModal()}
        {this._renderAssignLicenseDrawer()}

        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            {!this.props.hideHeader && <PageHeader className="pageHeader" title="Search Users" />}
            <Layout.Content>
              <CommonSearchBar alwaysEnabled defaultValue={this.state.searchObj.term} handleSearch={this.handleSearch} />
              {this._renderFilters()}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.users.length < 1} style={{ margin: '15px 0' }}> Export to xlsx </Button>
              </div>
              <Table className="adminSearchUsersTable"
                rowClassName={(record, index) =>
                  this._isExpiringCertificate(record) === true ? 'ant-table-row-warning' : ''
                }
                onRow={this._onRow} columns={this._getTableColumns()} dataSource={this.state.users} {...props}
              />
              <Row className="userSearchTotalRow" type="flex" align="center">
                <Tag className="userSearchTotalLabel" icon={<SearchOutlined/>}>{this.state.total} users found</Tag>
              </Row>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* UI privates renders */
  _renderSetPasswordModal() {
    return (
      <CommonSetPasswordModal isVisible={this.state.isSetPassModalVisible} app={this.props.app}
                          handleCancel={this.handleSetPassClose}
                          handleSubmissionComplete={this.handleSetPassClose}
                          {...Utils.propagateRef(this, 'setPassmodal')} />
                      );
  }
  _renderOrganizationMemberProfileModal() {
    if (!this._isOrgScoped()) return;
    return (
      <OrganizationMemberProfileModal app={this.props.app} onShouldReload={() => {}} {...Utils.propagateRef(this, 'organizationMemberProfileModal')} />
    );
  }
  _renderOrganizationSelectionModal() {
    //props are: app, requiresWorksafeValidation, allowOrgsListing, onSelection, allowEmptySelection
    return (<CommonOrganizationSelectionModal {...Utils.propagateRef(this, 'companySelectionModal')} app={this.props.app} allowOrgsListing onSelection={this.handleEmployerSelected}/>);
  }
  _renderFilters() {
    const enabledUsersFilter = this.props.app.sharedCache().getTenantConfig()?.enabledUsersFilter || [];
    const optionalUserSearchFilters = this.props.app.sharedCache().getTenantConfig()?.optionalUserSearchFilters || [];
    const courses = this.props.app.sharedCache().getAllUniqueCourses();
    const availableFilters = Globals.USER_FILTERS.filter(option => enabledUsersFilter.includes(option.key));
    return (
      <div className="usersFilters">
        <Row type='flex'>
          <Col className="filterItem">
            <strong>Status:</strong>
              <Select style={{ minWidth: 200 }} defaultValue={this.state.filters.status} size="large"
                onChange={this.handleChangeStatusFilter}>
                {availableFilters.map(option => (
                  <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                ))}
              </Select>
          </Col>
          <Col className="filterItem">
            <strong>Employer:</strong>
              {!this.state.selectedEmployer && <Button className="innerFilterItem" type="ghost" onClick={this.handleSearchEmployer}>Select an employer</Button>}
              {this.state.selectedEmployer && (
                <span className="innerFilterItem">
                  {this.state.selectedEmployer.name}
                  <Tooltip title="Remove Filter">
                    <Button icon={<CloseCircleOutlined />} type="link" onClick={this.handleDeselectEmployer} />
                  </Tooltip>
                </span>
              )}
          </Col>
          {this.state.filters.status != 'ENROLLED_COURSE' && <Col>
            <Button type="primary" size="large" style={{ marginLeft: 4 }}
              onClick={this.handleSearch.bind(this, this.state.searchObj.term)}>Filter</Button>
          </Col>}
        </Row>
        {optionalUserSearchFilters.length > 0 && <Row type="flex" style={{marginTop: 10}}>
          {optionalUserSearchFilters.indexOf("CERTIFICATION") != -1 && !this.props.app.sharedCache().isMonoCertification() && (
            <Col className="filterItem">
              <strong>Certification:</strong>
                <Select style={{ minWidth: 200 }} value={this.state.filters.certification} size="large"
                  onChange={this.handleChangeFilter('certification')} disabled={this.state.filters.status == 'ALL'}>
                  <Select.Option value="ALL">All</Select.Option>
                  {this.props.app.sharedCache().getTenantConfig().certifications.map(certification => (
                    <Select.Option key={certification.id} value={certification.id}>{certification.description}</Select.Option>
                  ))}
                </Select>
            </Col>
          )}
          {optionalUserSearchFilters.indexOf("REGISTRATION_DATE") != -1 && this.state.filters.status != 'ENROLLED_COURSE' && <Col className="filterItem">
            <strong>User Registered date:</strong>
            <DatePicker.RangePicker defaultValue={this.state.momentRange} size="large" onChange={this.handleSigninDateChange} />
          </Col>}
          {optionalUserSearchFilters.indexOf("CERTIFICATION_EXP_DATE") != -1 && this.state.filters.status != 'ENROLLED_COURSE' && <Col className="filterItem">
            <strong>Certification Expiration:</strong>
            <DatePicker.RangePicker defaultValue={this.state.expiryRange} size="large" onChange={this.handleExpiryDateChange} />
          </Col>}
        </Row>}
        {this.state.filters.status == 'ENROLLED_COURSE' && <Row type='flex' style={{marginTop: 10}}>
          <Col className="filterItem">
            <strong>Course(s):</strong>
            <Select mode='multiple' showSearch style={{width: 225}} value={this.state.filters.courseSpecsID}
              onChange={this.handleChangeFilter('courseSpecsID')} filterOption={(input, option) => {
                const course = (courses || []).find((c) => option.value == c.id);
                return `${course.description}`.toLowerCase().includes(input.toLowerCase());
              }}>
                {(courses || []).map((c) => {
                  return <Select.Option value={c.id} key={c.id}>{c.description}</Select.Option>;
                })}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>Course Reg. date:</strong>
            <DatePicker.RangePicker defaultValue={this.state.momentRange} size="large" onChange={this.handleRangeDateChange} />
          </Col>
          <Col>
            <Button type="primary" size="large" style={{ marginLeft: 4 }}
              onClick={this.handleSearch.bind(this, this.state.searchObj.term)}>Filter</Button>
          </Col>
        </Row>}
      </div>
    );
  }
  _renderAssignLicenseDrawer() {
    const { selectedUser } = this.state;
    return (
      <CommonAssignLicenseDrawer shouldShowUserSelect={false} shouldShowProductSelect={true} isVisible={this.state.isAssignLicenseDrawerVisible} users={selectedUser} onClose={this.handleAssignLicenseDrawerClose} app={this.props.app}/>
    )
  }

  /* UI Helpers */
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name', key: 'fullName.keyword', width: '15%', sorter: true,
        render: user => ( <Tooltip title={user.email}> {user.fullName} </Tooltip> ),
        sortOrder: sortedInfo.columnKey === 'fullName.keyword' && sortedInfo.order,
      },
      {
        title: 'Referred by', key: 'referredByName.keyword', dataIndex: 'referredByName',
        render: referredByName => referredByName || '-',
        width: '15%', sorter: true, sortOrder: sortedInfo.columnKey === 'referredByName.keyword' && sortedInfo.order,
        show: this._showThisColumn('REFERRED_BY'),
      },
      {
        title: 'Registered date', key: 'createdOn', width: '15%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
      },
      {
        title: 'Certificate number', dataIndex: 'memberNumber', key: 'memberNumber.keyword', width: 300,
        render: (memberNumber) => !memberNumber ? '-' : Object.entries(memberNumber).map(([certID, number]) => (
          <div key={certID}>{number} {this._getCertificationName(certID)}</div>
        )),
        show: this._showThisColumn('CERT_NUMBER'),
      },
      {
        title: 'Completion date', dataIndex: 'certificationDate', key: 'certificationDate', width: '15%',
        render: (certificationDate) => (
          !certificationDate
            ? 'N/A'
            : Object.entries(certificationDate).map(([certID, timestamp]) => (
              <div key={certID}>{Utils.getDateOnUIFormatByTimestamp(timestamp)} {this._getCertificationName(certID)}</div>
            ))
        ),
        show: this._showThisColumn('CERT_COMPLETION_DATE'),
      },
      {
        title: 'Cert Expiration date', dataIndex: 'certificationExpirationDate', key: 'certificationExpirationDate', width: '15%',
        render: (certificationExpirationDate) => (
          !certificationExpirationDate
            ? 'N/A'
            : Object.entries(certificationExpirationDate).map(([certID, timestamp]) => (
              <div key={certID}>{Utils.getDateOnUIFormatByTimestamp(timestamp)} {this._getCertificationName(certID)}</div>
            ))
        ),
        show: this._showThisColumn('CERT_EXP_DATE'),
      },
      {
        title: 'State', key: 'certificationDate', width: '10%', dataIndex: 'certificationDate',
        render: (certificationDate, record) => (
          !certificationDate || !record.certificationOriginalDate
            ? ''
            : Object.entries(certificationDate).map( ([certID, timestamp]) => {
                let description = this._getStatusDescription(record, certID, timestamp);
                return <div key={certID}>{description} {this._getCertificationName(certID)}</div>
              })
        ),
        show: this._showThisColumn('STATUS'),
      },
      {
        title: 'ITA #', key: 'itaNumber.keyword', width: '10%', sorter: true, dataIndex: 'itaNumber',
        sortOrder: sortedInfo.columnKey === 'itaNumber.keyword' && sortedInfo.order,
        show: this._showThisColumn('ITA_NUMBER'),
      },
    ].filter(item => !item.show);
    //Org members view does d
    const { organizationMemberProfile } = this.props.app.sharedCache().getTenantConfig();
    const usersView = this.props.location.pathname.includes('/users');

    if (!this.props.isOrgMembersView || (this._isOrgScoped() && organizationMemberProfile) || this.props.app.isAdmin()) {
      columns.push({
        title: 'Actions', width: '15%', key: 'Actions',
         render: props => {
           return (<span className='tableButtonContainer'>
                    {(this._isOrgScoped() && organizationMemberProfile) && <Tooltip placement="bottomLeft" title='Student settings'>
                       <Button variant="none" icon={<UserOutlined />} shape="circle" onClick={this.handleEditOrgMemberProfile.bind(this, props)}/>
                     </Tooltip>}
                     {' '}
                     {!this.props.isOrgMembersView && (this.props.app.isAdmin() || this.props.app.isSysAdmin()) && <Tooltip placement="bottomLeft" title='Set password'>
                       <Button variant="none" icon={<ToolOutlined />} shape="circle" onClick={this.handleUserPasswordSet.bind(this, props)}/>
                     </Tooltip>}
                     {' '}
                     {!this.props.isOrgMembersView && <Tooltip placement="bottomLeft" title='Licenses'>
                       <Button variant="none" icon={<FileDoneOutlined />} shape="circle" onClick={this.handleUserLicenses.bind(this, props)}/>
                     </Tooltip>}
                     {' '}
                     {(this.props.app.isOrgManager() || this.props.app.isAdmin() || this.props.app.isSysAdmin()) && <Tooltip placement="bottomLeft" title='Courses'>
                       <Button variant="none" icon={<BookOutlined />} shape="circle" onClick={this.handleUserCourses.bind(this, props)}/>
                     </Tooltip>}
                     {' '}
                     {this.props.app.isOrgManager() && (
                       <Tooltip placement="bottomLeft" title='Assign License'>
                        <Button variant="none" icon={<FileAddOutlined />} shape="circle"  onClick={this.handleAssignLicense.bind(this, props)}/>
                      </Tooltip>
                     )}
                     {' '}
                     {!organizationMemberProfile?.orgModDisabled && !organizationMemberProfile?.orgModEmployeeMode && !this.props.app.isOrgManager() && <Tooltip placement="bottomLeft" title='Refresh Org Membership'>
                       <Button variant="none" icon={<UserSwitchOutlined />} shape="circle" onClick={this.handleRefreshOrgMembership.bind(this, props)}/>
                      </Tooltip>}
                     {' '}
                     {!usersView && (this.props.app.isAdmin() || this.props.app.isOrgManager) &&(
                        <Tooltip placement="bottomLeft" title={`Remove ${props.fullName}`}>
                          <Button variant="none" icon={<DeleteOutlined />} shape="circle" onClick={this.handleRemoveMember.bind(this, props)}/>
                        </Tooltip>
                     )}
                   </span>);
         }
       });
    } return columns;
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.onRowSelection(record);
      }, // click row
      onDoubleClick: () => {
        this.onRowSelection(record);
      }, // double click row
    };
  };

  //Filters and URL support
  _getInitialState() {
    let pState = {};
    try { pState = JSON.parse(Buffer.from(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Filters), 'base64').toString('ascii') || '{}'); } catch (e) {}
    console.log(pState)
    const search = pState?.searchObj?.term || null;
    const status = pState?.filters?.status  || Globals.USER_FILTERS[0].key;
    const signinDate1 = pState?.filters?.signinDate1 || null;
    const signinDate2 = pState?.filters?.signinDate2 || null;
    const certExpDate1 = pState?.filters?.certExpDate1 || null;
    const certExpDate2 = pState?.filters?.certExpDate2 || null;
    const currentPage = pState?.currentPage || 1;
    const columnKey = pState?.sortedInfo?.columnKey || 'createdOn';
    const order = pState?.sortedInfo?.order  || 'descend';
    const certification = pState?.filters?.certification || 'ALL';
    const courseSpecsID = pState?.filters?.courseSpecsID || [];

    const momentRange = [];
    if (signinDate1) { momentRange.push(moment(new Date(signinDate1))); }
    if (signinDate2) { momentRange.push(moment(new Date(signinDate2))); }
    const expiryRange = [];
    if (certExpDate1) { expiryRange.push(moment(new Date(certExpDate1))); }
    if (certExpDate2) { expiryRange.push(moment(new Date(certExpDate2))); }

    return {
      momentRange,
      expiryRange,
      searchObj: { term: search || null },
      filters: {
        status: status, courseSpecsID,
        signinDate1: signinDate1 || null,
        signinDate2: signinDate2 || null,
        certExpDate1: certExpDate1 || null,
        certExpDate2: certExpDate2 || null,
        certification,
      },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }
  _setSearchQueryParams() {
    const cleanState = this.state;
    delete cleanState.users;
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Filters, Buffer.from(JSON.stringify(cleanState)).toString('base64'));
  }
  _getSearchFilter() {
    const filters = { ...this.state.filters };
    //cert
    filters.certSpecsID = filters.certification;
    delete filters.certification;
    if ((!filters.certSpecsID || filters.certSpecsID == 'ALL') || this.props.app.sharedCache().isMonoCertification()) delete filters.certSpecsID;
    //org
    if (this._isOrgScoped()) filters.organization = this._getScopedOrgID();
    //employer
    if (this.state.selectedEmployer?.id) filters.employerID = this.state.selectedEmployer?.id;
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //Use dates for other purpose when enrolled course
    if (filters.status == 'ENROLLED_COURSE') {
      filters.registerDate1 = filters.signinDate1;
      filters.registerDate2 = filters.signinDate2;
      delete filters.signinDate1;
      delete filters.signinDate2;
    }
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...filters };
  }

  // Utils
  _isOrgScoped() {
    if (this.props.defaultOrgID) return true;
    if (this.props.app.isOrgManager()) return true;
    return false;
  }
  _getScopedOrgID() {
    if (this.props.defaultOrgID) return this.props.defaultOrgID;
    else if (this.props.app.isOrgManager()) return this.props.app.urlManager.selectedOrg;
    return null;
  }
  _getCertificationName(certID) {
    if (this.props.app.sharedCache().isMonoCertification()) return null;
    return `(${this.props.app.sharedCache().getCertificationByID(certID)?.description || 'unkwown'})`;
  }
  _isExpiringCertificate(record) {
    const expiryPeriod = Utils.timestampAfterDays(this.props.app.sharedCache().getTenantConfig().certifications[0].enterMaintenanceDays)
    const anyExpiring = record.certificationExpirationDate && Object.values(record.certificationExpirationDate).find(e => e <= expiryPeriod)
    return (anyExpiring) ? true : false
  }

  // API call
  async _removeMember(record) {
    this.startLoading();
    const orgID = this.props.match.params.id;
    const resp = await this.props.app.organization.organizationAppMember.removeOrganizationAppMember(
      this.props.app.urlManager.selectedOrg || orgID, record.id,
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200) {
      message.success('User successfully removed!');
      this.setState((prevState) => ({
        ...prevState,
        users: prevState.users.filter(user => user.id != record.id),
      }));
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }

  _showThisColumn(columnName) {
    const { settingsDisplayOptions } = this.props.app.sharedCache().getTenantConfig();
//    return ! (settingsDisplayOptions?.optionalSearchColumns?.find(opt => opt == columnName) || true);
    return (settingsDisplayOptions?.optionalSearchColumns) ? ! settingsDisplayOptions?.optionalSearchColumns.find(opt => opt == columnName) : false;
  }

  _getStatusDescription(record, certID, timestamp) {
    let keyID = Object.keys(record.certificationOriginalDate).find(id => id === certID);
    if (!record.certificationOriginalDate[keyID]) return Globals.Certification_State.CERTIFIED;
    return (record.certificationOriginalDate[keyID] == timestamp) ? Globals.Certification_State.CERTIFIED : Globals.Certification_State.RECERTIFIED;
  }

  async _refreshOrgMembership(record) {
    this.startLoading();

    const resp = await this.props.app.api.user.refreshOrgMembership(record.id);
    if (!this._isMounted) return; 
    if (resp.statusCode == 200) {
      message.success('User successfully refreshed');
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
