import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Divider } from 'antd';
import { FcMultipleInputs, FcCurrencyExchange } from "react-icons/fc";
import { IoSettings, IoLocation, IoSchool, IoPricetags } from 'react-icons/io5';
import { FaUserCheck, FaAsterisk, FaBuilding, FaRegQuestionCircle } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { GrCertificate } from 'react-icons/gr';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { BsBoxSeam } from "react-icons/bs";
import { ImInsertTemplate } from 'react-icons/im';
//
import CustomComponent from '../../../components/CustomComponent';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/AdminSettingsView.less';
//
export default class AdminSettingsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleNavigate(route) {
    this.props.app.urlManager.pushPage(route);
  }
  handleRedirectToTenantSettings() {
    this.props.app.urlManager.redirectToPortal(`/app/config/tenant/${this.props.app.tenantID}`)
  }

  render() {
    const { settingsDisplayOptions } = this.props.app.sharedCache().getTenantConfig();
    const isSysAdmin = this.props.app.isSysAdmin();
    const commonIconsProps = {
      size: 60, style: { marginRight: '15px' }
    };
    const primaryColor = this.props.app.themeManager.primaryColor;
    const itemsPerRow = 3;

    let groups = [
      {
        title: 'Main Configuration',
        options: Utils.separateArrayByGroups([
          {
            show: this.props.app.isSysAdmin(),
            icon: <IoSettings {...commonIconsProps} color={primaryColor}/>,
            title: 'Main Settings',
            description: 'Manage this tenant/program options.',
            action: this.handleRedirectToTenantSettings,
          },
          {
            show: this.props.app.isAdmin(),
            icon: <GrCertificate {...commonIconsProps} color={primaryColor}/>,
            title: 'Certifications',
            description: 'Manage certifications and courses',
            route: config.ApplicationRoutes.certifications,
          },
          {
            show: settingsDisplayOptions?.showSessions || isSysAdmin,
            icon: <IoSchool {...commonIconsProps} />,
            title: 'Sessions',
            description: 'Manage Sessions.',
            route: config.ApplicationRoutes.sessions,
          },
          {
            show: settingsDisplayOptions.showFAQ,
            icon: <FaRegQuestionCircle {...commonIconsProps} />,
            title: 'FAQ',
            description: 'Manage FAQ.',
            route: config.ApplicationRoutes.faqManagement,
          },
        ], itemsPerRow),
      },
      {
        title: 'Session Resources',
        options: Utils.separateArrayByGroups([
          {
            show: settingsDisplayOptions?.showLocations,
            icon: <IoLocation {...commonIconsProps} color="#F34335" />,
            title: 'Locations',
            description: 'Manage Regions and Cities.',
            route: config.ApplicationRoutes.locations,
          },
          {
            show: settingsDisplayOptions?.showVenues,
            icon: <FaBuilding {...commonIconsProps}/>,
            title: 'Venues',
            description: 'Manage Venues.',
            route: config.ApplicationRoutes.venues,
          },
          {
            show: settingsDisplayOptions?.showInstructors,
            icon: <GiTeacher {...commonIconsProps} color="#78909C" />,
            title: 'Instructors',
            description: 'Manage Instructors and the Regions and Courses they are related to.',
            route: config.ApplicationRoutes.instructors,
          },
          {
            show: settingsDisplayOptions?.showSessions || isSysAdmin,
            icon: <ImInsertTemplate {...commonIconsProps} />,
            title: 'Templates',
            description: 'Manage Session Templates.',
            route: config.ApplicationRoutes.templates,
          },
          {
            show: isSysAdmin,
            icon: <HiOutlineStatusOnline {...commonIconsProps} />,
            title: 'Course Providers',
            description: 'Manage Course Providers.',
            route: config.ApplicationRoutes.courseProviders,
          },
        ], itemsPerRow),
      },
      {
        title: 'Certification Resources',
        options: Utils.separateArrayByGroups([
          {
            show: this.props.app.isSysAdmin(),
            icon: <BsBoxSeam {...commonIconsProps} />,
            title: 'Products',
            description: 'Manage products.',
            route: config.ApplicationRoutes.products,
          },
          {
            show: settingsDisplayOptions?.showProducts,
            icon: <FcCurrencyExchange {...commonIconsProps} />,
            title: 'Prices',
            description: 'Manage Prices.',
            route: config.ApplicationRoutes.prices,
          },
          {
            show: settingsDisplayOptions?.showVouchers,
            icon: <IoPricetags {...commonIconsProps} />,
            title: 'Vouchers',
            description: 'Manage Vouchers.',
            route: config.ApplicationRoutes.vouchers,
          },
          {
            show: settingsDisplayOptions?.showTrainingProviders,
            icon: <FaUserCheck {...commonIconsProps} />,
            title: 'Training Providers',
            description: 'Manage Training Providers.',
            route: config.ApplicationRoutes.trainingProviders,
          },
          {
            show: settingsDisplayOptions?.showEquivalences,
            icon: <FcMultipleInputs {...commonIconsProps} />,
            title: 'Equivalences',
            description: 'Manage Equivalences.',
            route: config.ApplicationRoutes.equivalencies,
          },
          {
            show: settingsDisplayOptions?.showRequirements,
            icon: <FaAsterisk {...commonIconsProps} />,
            title: 'Requirements',
            description: 'Manage Requirements.',
            route: config.ApplicationRoutes.requirements,
          },
        ], itemsPerRow),
      },
    ];

    groups = groups.map(
      (group) => ({
        ...group,
        options: group.options.map((row) => row.filter(option => !!option.show)).filter(option => option.length > 0),
      })
    ).filter(option => option.options.length > 0);

    return (
      <Layout.Content className="pageContent">
        <PageHeader className="pageHeader" title="Settings" />
        <WhiteBox>
          {groups.map((group) => (
            <React.Fragment key={group.title}>
              <Divider orientation="left">{group.title}</Divider>

              {group.options.map((row, index) => (
                <Row gutter={24} key={String(index)} style={{ marginBottom: 24 }}>
                  {row.map((option) => (
                    option.show
                      ? (
                        <Col span={8} key={option.title}>
                          <div className="settings-tile" onClick={option.action || this.handleNavigate.bind(this, option.route)}>
                            <div className="icon-container">{option.icon}</div>
                            <div className="tile-info">
                              <strong>{option.title}</strong>
                              <span>{option.description}</span>
                            </div>
                          </div>
                        </Col>
                      )
                      : null
                  ))}
                </Row>
              ))}
            </React.Fragment>
          ))}
        </WhiteBox>
      </Layout.Content>
    );
  }
}
