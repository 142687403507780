import config from '../config/config';
import Utils from './Utils';
//
const urlParse = require('url-parse');
//
export default class ThemeManager {
  constructor(auth) {
    this.mapping = config.ThemeDomainsMapping;
    this.auth = auth;
    this.theme = this._initializeTheme();
  }
  //Public helpers
  get primaryColor() { return this.theme.colors['@primary-color']; }
  //
  _initializeTheme() {
    const theme = this._getCurrentDomainTheme();
    const themeConfig = require(`../themes/${theme.themeKey}/config`).default;
    const colors = require(`../themes/${theme.themeKey}/colors`);``
    console.debug(`Using theme: ${theme.themeKey}`);

    // Update less and also antd theme
    if (window.less) window.less.modifyVars(colors);
    this._updateFavIcon(theme);

    const themeObject = {
      config: {
        ...themeConfig,
        ...theme, /* tenantID, themeKey, adminRole, idmExternalAuthDomain, ... (others) */
      },
      colors,
      getLogo: ({ type }) => {
        if (type == 'center') {
          return require(`../themes/${theme.themeKey}/images/logoCenter.png`).default;
        }
        if (type == 'footer') {
          return require(`../themes/${theme.themeKey}/images/logoFooter.png`).default;
        }
        if (type == 'left') {
          return require(`../themes/${theme.themeKey}/images/logoLeft.png`).default;
        }
        return null;
      },
      applicationName: `${themeConfig.applicationName}` + (config.IncludesStageOnName ? ` - [${Utils.camelizeString(config.Stage)}]` : '')
    }

    return themeObject;
  }

  _getCurrentDomainTheme() {
    const curr = urlParse(window.location.href);
    for (let domain of Object.keys(this.mapping)) {
      if (domain.indexOf('*.') != -1) {
        if (curr.hostname.indexOf(domain.replace('*.', '')) != -1) return this.mapping[domain];
      } else if (curr.hostname.indexOf(domain) != -1) return this.mapping[domain];
      // 0=ncso, 1=srbc, 2=training, 3=asbestos, 4=tcp, 5=csam
    } return this.mapping[Object.keys(this.mapping)[2]];
  }

  _updateFavIcon(theme) {
    const favIcon = document.getElementById("favicon");
    const baseUrl = [location.protocol, '//', location.host, `/favicons/${theme.themeKey}.ico`].join('');
    favIcon.href = baseUrl;
  }
}
