import { BookOutlined, CaretDownOutlined, CaretUpOutlined, CopyOutlined, DownloadOutlined, HomeOutlined, ReloadOutlined, RetweetOutlined, WalletOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Row, Tooltip, message } from 'antd';
import Animate from 'rc-animate';
import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';
import velocity from 'velocity-animate';
//
import '../../../../assets/stylesheets/CourseCard.less';
//
import CustomComponent from '../../../../components/CustomComponent';
import UtilsSession from '../../../../components/UtilsSession';
import Globals from '../../../../config/Globals';
import CommonLoadingView from '../../../commonComponents/CommonLoadingView';
//
import { CourseCardActionsProvider } from './Subcomponents/CourseCardActionsContext';
//Tabs
import Utils from '../../../../components/Utils';
import CourseTabOverallTab from './CourseTabOverallTab';
import CourseTabPaymentsTab from './CourseTabPaymentsTab';
import CourseTabResourcesTab from './CourseTabResourcesTab';
import CourseTabResultsTab from './CourseTabResultsTab';
import CourseTabUploadTab from './CourseTabUploadTab';
import CourseTabSessionInfoTab from './CourseTabSessionInfoTab';
import getCardStatusHeaderComponent from './Subcomponents/getCardStatusHeaderComponent';
//Modal
import CommonLicensePurchaseModal from '../../../commonComponents/Modals/CommonLicensePurchaseModal';
import CommonLicenseRefundModal from '../../../commonComponents/Modals/CommonLicenseRefundModal';

//
export default class CourseCard extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.courseSpecs = null; //will be set on '_loadState'
    const initialState = this._loadState();
    this.state = {
      currentTab: Globals.CourseTabCourseCardTabs.OVERALL, isCollapsed: false, isVisibleRefund: false, isVisiblePurchase: false,
      isLoading: false, detailsVisible: initialState.startUncollapsed || !initialState.collapsibleCard,
      canOpenUploadModal: false, isCourseValid: null,
      ...initialState,
    };
    //tabs controller
    this.tabsInstances = {};
    this.animationControllerCollapsed = !initialState.startUncollapsed;
  }

  //Life cycle
  async componentDidMount() {
    await this._loadCourseSession();

    //const bypassShowCollapsedStatus = [
    //  Globals.CourseStatuses.COURSE_AVAILABLE,
    //  Globals.CourseStatuses.WAITING_RESULT_RELEASE,
    //];

    //if (this.courseSpecs?.showCollapsed && !bypassShowCollapsedStatus.includes(this.state.status)) { removed by Rescio to force collapsing only when course is complete
    if (this.courseSpecs?.showCollapsed && this.state.status == Globals.CourseStatuses.COMPLETED) {
      this.setState({ isCollapsed: true });
    }
  }

  async reloadCertAndSession() {
    // Should not run in parallel because _loadCourseSession
    // depends on the certProcess object updated to execute the logic properly
    await this.props.onUpdate();
    await this._loadCourseSession();
  }

  //Public
  reloadState() { this._loadState(true); }

  // Course actions
  handleLaunch() {
    if (this.state.session?.type != Globals.Session_Type.SCHEDULED_VILT && this.state.session?.type != Globals.Session_Type.SCHEDULED_WEBINAR) {
      this._launchOnlineCourse(this.state.currentCourse, this.courseSpecs);
      return;
    }

    if (this.state.session?.accessLink) {
      window.open(this.state.session?.accessLink);
      return;
    }

    message.error('A launch URL for this event was not configured!');
  }
  async handleCheckCourseResults() { this._checkOnlineCourseResults(this.state.currentCourse); }
  handleEndCooldown() { this._forceCourseCooldownEnd(this.state.currentCourse); }
  async handleCancelCourse() { await this._cancelCourse(this.state.currentCourse) }
  handleRefundModal(order, policy, cancelEnrol) {
    this.setState({ isVisibleRefund: !this.state.isVisibleRefund }, () => {
      if (this.state.isVisibleRefund) this.refundModal.loadModalInfo(order, policy);
      if (!this.state.isVisibleRefund) this._loadState(true);
      if (cancelEnrol) this.cancelEnrolment(this.state.currentCourse);
    });
  }
  handleChargeModal(order, policy) {
    if (!this.state.isVisiblePurchase) this.purchaseModal.loadModalInfo(order.productID, policy.charges.cancelationCharge, null, policy);
    this.setState({ isVisiblePurchase: !this.state.isVisiblePurchase }, () => {
      if (!this.state.isVisiblePurchase) this._loadState(true);
    });
  }
  handleUnlockCourse() { this._releaseOnlineCourseAccess(this.state.currentCourse); }
  handleOnRetake() { this._onRetake(this.state.currentCourse) }
  handleOnUpload() {
    const canOpenUploadModal = !(this.state.currentCourse.approvalState && !this.props.app.isAdmin());
    this.setState({ currentTab: Globals.CourseTabCourseCardTabs.UPLOAD, detailsVisible: true, canOpenUploadModal });
  }
  handleOnRevoke() {this._onAdminRevoke(this.state.currentCourse); }
  // Tab Actions
  handleTabChange(tabIndex) { this.setState({ currentTab: tabIndex, detailsVisible: true, canOpenUploadModal: false }); }
  handleVisibilityToogle() {
    this.setState({ detailsVisible: !this.state.detailsVisible });
  }
  async handleRequestApproval() { await this._onRequestApproval() }

  handleForceCheck() {
    this.setState(prevState => ({
      ...prevState,
      currentCourse: {
        ...(prevState.currentCourse ?? {}),
        sessionID: null,
      },
    }), this._loadCourseSession);
  }

  //UI
  render() {
    const { currentTab } = this.state;
    const courseSpecs = this.courseSpecs;
    const downloadTab = [];
    if ([
      Globals.CertificationProcess_CertificationPrintType.CERT,
      Globals.CertificationProcess_CertificationPrintType.CARD,
    ].includes(courseSpecs.courseCertificateType)) {
      downloadTab.push({
        name: (<Tooltip title="Download"><DownloadOutlined style={{fontSize: 20}} /></Tooltip>),
        key: Globals.CourseTabCourseCardTabs.DOWNLOAD,
        onClick: this._downloadCertificate,
        disabled: this.state.status !== Globals.CourseStatuses.COMPLETED,
      });
    }

    const tabs = [
      { name: (<HomeOutlined style={{fontSize: 20}} />), key: Globals.CourseTabCourseCardTabs.OVERALL, render: this._renderOverallTab },
      { name: (<><WalletOutlined style={{marginRight: 7}}/>Purchases</>), key: Globals.CourseTabCourseCardTabs.PAYMENTS, render: this._renderPaymentsTab },
      { name: (<><RetweetOutlined style={{marginRight: 7}}/>Attempts</>), key: Globals.CourseTabCourseCardTabs.RESULTS, render: this._renderResultsTab },
      ...downloadTab,
    ];
    if (this.state.currentCourse?.sessionID) {
      tabs.push({
        name: (<><InfoCircleOutlined style={{marginRight: 7}}/>Session</>),
        key: Globals.CourseTabCourseCardTabs.SESSION,
        render: this._renderSessionInfo },
      );
    }
    /* bigger than one so we don't display line breaks and other input entered by error */
    if (this.state.session?.content?.sections?.[0]?.content?.trim()?.replace(/\\n/g, '')?.length > 1) {
      tabs.push({
        name: (<><CopyOutlined style={{marginRight: 7}}/>Resources</>),
        key: Globals.CourseTabCourseCardTabs.RESOURCES,
        render: this._renderResources,
      });
    }
    /* Only render upload tab when course is available and course accept uploads */
    if (this.state.currentCourse && (courseSpecs.allowStudentUploads == true || courseSpecs.courseType == Globals.Course_Types_Keys.ASSIGNMENT || this.props.app.isAdmin())) {
      tabs.push({
        name: <Tooltip placement="bottomLeft" title="Upload Documents"><BookOutlined/></Tooltip>,
        key: Globals.CourseTabCourseCardTabs.UPLOAD,
        render: this._renderUploadTab
      });
    }

    return (
      <div className="card-course">
        {this._renderRefundModal()}
        {this._renderChargeModal()}
        {this._renderTabHeader(courseSpecs, currentTab, tabs)}
        {!this.state.isCollapsed && this._renderCardDescription(courseSpecs)}
        <div className="course-body">
          <CommonLoadingView isLoading={this.state.isLoading}/>
          <CourseCardActionsProvider
            value={{
              launch: this.handleLaunch, checkCourseResults: this.handleCheckCourseResults,
              endCooldown: this.handleEndCooldown, cancelCourse: this.handleCancelCourse,
              unlockCourse: this.handleUnlockCourse,
              onCourseActivate: () => this.props.onLicenseRedeem(this.courseSpecs, true),
              onAddResult: () => this.props.onAddResult(this.state.currentCourse),
              onSchedule: () => this.props.onSchedule(this.state.currentCourse),
              onLicensePurchase: () => this.props.onLicensePurchase(this.courseSpecs),
              onLicenseRedeem: () => this.props.onLicenseRedeem(this.courseSpecs, false, this.state.availableKeys),
              onUpdate: this.props.onUpdate,
              reloadCertAndSession: this.reloadCertAndSession,
              onRetake: this.handleOnRetake,
              onUpload: this.handleOnUpload,
              onAdminRevoke: this.handleOnRevoke,
              onRequestApproval: this.handleRequestApproval,
              forceCheck: this.handleForceCheck,
            }}>
              {tabs.map(tab => {
                return (
                  this._renderAnimationContext(
                    tab?.render?.((!tab.hidden && tab.key === currentTab && !this.state.isCollapsed)),
                    tab.key
                  )
                )
              })}
            {this.state.collapsibleCard && this._renderCollapsibleButtonRow()}
          </CourseCardActionsProvider>
        </div>
      </div>
    );
  }

  // Private UI (misc.)
  _renderCollapsibleButtonRow() {
    return (
      <Row type="flex" justify="center" className={'collapsibleRow'}>
        <Button type="link" className={'collapsibleSectionButton' + (this.state.detailsVisible ? ' hide' : '')}
                onClick={this.handleVisibilityToogle}>{this.state.detailsVisible ? "Hide" : "Show details"}</Button>
      </Row>
    );
  }
  // Private UI (Tab controller)
  _renderTabHeader(courseSpecs, currentTab, tabs) {
    //
    let logo = require('../../../../assets/images/coursesLogos/default.png').default;
    if (courseSpecs.uiLogo) {
      try { logo = require(`../../../../assets/images/coursesLogos/${courseSpecs.uiLogo}.png`).default; } catch {}
    }
    //
    return (
      <header
        {...(
          this.state.isCollapsed
            ? { style: { margin: 0, padding: 0, border: 0 } }
            : {}
        )}
      >
        <div className="course-details">
          <img src={logo} alt={courseSpecs.displayName} height="60" />
          <div className="course">
            <strong>{courseSpecs.displayName}</strong>
            {this._renderCardStatus()}
          </div>
        </div>
        <div className="menu">
          {tabs.map((step, i) => {
            const changeTab = () => this.handleTabChange(step.key);
            const handleClick = step.onClick || changeTab;
            return (
              <button type="button" key={i} className={currentTab === step.key ? 'active' : ''}
                      onClick={handleClick} disabled={!!step.disabled}>
                {step.name}
              </button>
            );
          })}
          <Tooltip title="Reload card information">
            <button
              type="button"
              onClick={this.reloadCertAndSession}
            >
              <ReloadOutlined />
            </button>
          </Tooltip>
          <button
            type="button"
            style={{ marginLeft: 24 }}
            onClick={() => this.setState(prev => ({ isCollapsed: !prev.isCollapsed }))}
          >
            {this.state.isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
          </button>
        </div>
      </header>
    );
  }
  _renderCardDescription(courseSpecs) {
    if (!courseSpecs.cardDescription) return (<></>);
    return (
      <div>
        <ReactMarkdown children={courseSpecs.cardDescription || ''} />
        <Divider/>
      </div>
    );
  }
  _renderCardStatus() {
    const { status } = this.state;
    const courseSpecs = this.courseSpecs;
    const currentStatus = (
      (status === Globals.CourseStatuses.COURSE_AVAILABLE || status === Globals.CourseStatuses.WAITING_RESULT_RELEASE)
      && courseSpecs.courseType === Globals.Course_Types_Keys.EXTERNALREQUIREMENT
        ? Globals.CourseStatuses.CHECKING_STATUS
        : (status === Globals.CourseStatuses.COMPLETED && courseSpecs.passUiTerm
          ?  'PassUiTerm'
          : (status === Globals.CourseStatuses.FAILED && courseSpecs.failUiTerm
            ? 'FailUiTerm'
            : status))
    );

    return getCardStatusHeaderComponent(currentStatus, this.courseSpecs);
  }

  // Private UI (Tabs)
  _renderOverallTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CourseTabCourseCardTabs.OVERALL]) return <Fragment key='10'></Fragment>;
    this.tabsInstances[Globals.CourseTabCourseCardTabs.OVERALL] = true;
    return (
      <CourseTabOverallTab
        isVisible={visible} collapsed={this.state.detailsVisible}
        key={Globals.CourseTabCourseCardTabs.OVERALL}
        courseSpecs={this.courseSpecs}
        currentCourse={this.state.currentCourse}
        status={this.state.status}
        app={this.props.app}
        certificationProcess={this.props.certificationProcess}
        certificationSpecs={this.props.certificationSpecs}
        session={this.state.session}
        user={this.props.user}
        onUpdate={this.props.onUpdate}
        onLicensePurchase={this.props.onLicensePurchase}
        onLicenseRedeem={this.props.onLicenseRedeem}
        disableActions={this.props.disableActions}
        availableKeys={this.state.availableKeys}
      />
    );
  }
  _renderPaymentsTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CourseTabCourseCardTabs.PAYMENTS]) return <Fragment key='20'></Fragment>;
    this.tabsInstances[Globals.CourseTabCourseCardTabs.PAYMENTS] = true;
    return (
      <CourseTabPaymentsTab key={Globals.CourseTabCourseCardTabs.PAYMENTS} collapsed={this.state.detailsVisible}
        app={this.props.app} isVisible={visible} coursesIDs={this.props.coursesIDs}
        certificationProcess={this.props.certificationProcess}
      />
    );
  }
  _renderResultsTab(visible) {
    //lazy load logic
    if (!visible && !this.tabsInstances[Globals.CourseTabCourseCardTabs.RESULTS]) return <Fragment key='30'></Fragment>;
    this.tabsInstances[Globals.CourseTabCourseCardTabs.RESULTS] = true;
    return (
      <CourseTabResultsTab key={Globals.CourseTabCourseCardTabs.RESULTS}
        app={this.props.app} isVisible={visible} collapsed={this.state.detailsVisible}
        resultsHistory={this.state.resultsHistory}
        courseSpecs={this.courseSpecs}
        currentCourse={this.state.currentCourse}
        onDownload={this._downloadCertificate}
      />
    );
  }
  _renderResources(visible) {
    const resources = this.state.session?.content?.sections?.[0]?.content;
    return <CourseTabResourcesTab isVisible={visible} resources={resources} collapsed={this.state.detailsVisible}  />
  }

  _renderUploadTab(visible) {
    if (!visible) return <Fragment key={Globals.CourseTabCourseCardTabs.UPLOAD}></Fragment>;
      this.tabsInstances[Globals.CourseTabCourseCardTabs.UPLOAD] = true;
      const readOnly = this.state.currentCourse.approvalState && !this.props.app.isAdmin();
      return (
        <CourseTabUploadTab key={Globals.CourseTabCourseCardTabs.UPLOAD}
          app={this.props.app}
          isVisible={visible}
          collapsed={this.state.detailsVisible}
          currentCourse={this.state.currentCourse}
          certificationProcess={this.props.certificationProcess}
          user={this.props.user}
          onUpdate={this.props.onUpdate}
          onRequestApproval={this.handleRequestApproval}
          canOpenUploadModal={this.state.canOpenUploadModal}
          readOnly={readOnly}
        />
      );
  }

  _renderSessionInfo(visible) {
    const venue = this.props.app.sharedCache().getVenueByID(this.state.session?.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    return <CourseTabSessionInfoTab isVisible={visible} session={this.state.session} currentCourse={this.state.currentCourse} app={this.props.app} venue={venue} city={city} collapsed={this.state.detailsVisible}  />
  }

  // Private UI (Modals)
  _renderRefundModal() {
    return (
      <CommonLicenseRefundModal isVisible={this.state.isVisibleRefund}
        app={this.props.app} {...Utils.propagateRef(this, 'refundModal')} onChange={this.handleRefundModal}/>
    );
  }
  _renderChargeModal() {
    const { user } = this.props;
    return (
      <CommonLicensePurchaseModal
        {...Utils.propagateRef(this, 'purchaseModal')}
        isVisible={this.state.isVisiblePurchase}
        onChange={this.handleChargeModal}
        onCancelation={this.cancelEnrolment}
        app={this.props.app}
        user={user}
        cancelCharge
        fixedQuantity
        onRequiresAttention={modal => {
          this.purchaseModal = modal;
          this.handleChargeModal();
        }}
      />
    );
  }

  /* Private Helpers */
  _getCurrentCourseStatus(session) {
    let status = this.state.status ;
    const { currentCourse } = this.state;
    if (!currentCourse) return status;
    //
    if (currentCourse.state == Globals.Course_State.FAIL) { //Failed
      status = Globals.CourseStatuses.FAILED;
      //Might be on cooldown
      if (this.props.certificationProcess.state == Globals.CertificationProcess_State.COOLDOWN) status = Globals.CourseStatuses.COOLDOWN;
    } else if (currentCourse.invalidated) { //Invalidate (important to leave invalidated after failed because a failed course can also be invalidated, but we want to display the failed state still)
      //Can be cancelled
      if (currentCourse.state == Globals.Course_State.CANCELLED) status = Globals.CourseStatuses.REVOKED;
      //Or INVALIDATED
      else status = Globals.CourseStatuses.INVALIDATED;
    } else if (currentCourse.state == Globals.Course_State.PASS) { //Passed :)
      //Check for expiration
      if (currentCourse.expiryDate && currentCourse.expiryDate != -1 && currentCourse.expiryDate < Date.now()) status = Globals.CourseStatuses.EXPIRED;
      else status = Globals.CourseStatuses.COMPLETED;
    } else if (currentCourse.state == Globals.Course_State.PENDING) { //Pending
      //Does not have session, course is available if does not have a ticket
      if (!session) {
        if (currentCourse.sessionTicketID) status = Globals.CourseStatuses.READY_TO_SCHEDULE; //waiting scheduled
        else status = Globals.CourseStatuses.PAYMENT_REQUIRED; //TODO: this is a error case, where course is pending but does not have ticket neither session!
      }
      //Waiting result release
      else if (session.results[0]) {
        status = Globals.CourseStatuses.WAITING_RESULT_RELEASE;
      }
      //Unlocked
      else if (session.enrolments[0] && session.enrolments[0].accessRelease != Globals.SessionEnrolment_AccessRelease.LOCKED) {
        if (session?.launchSchedule) {
          const { timezone } = this.props.app.sharedCache().getTenantConfig();
          if (UtilsSession.isOnLaunchSchedule(session.launchSchedule, timezone)) {
            status = Globals.CourseStatuses.COURSE_AVAILABLE;
          } else {
            status = Globals.CourseStatuses.SCHEDULED;
          }
        } else if (session.enrolments[0].accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO && !UtilsSession.isOnSessionPeriods(session)) { //Waiting for date
          status = Globals.CourseStatuses.SCHEDULED;
        } else { //Available (released or in range date range for auto)
          status = Globals.CourseStatuses.COURSE_AVAILABLE;
        }
      }
      // Forbidden
      else if (this.props.app.isOrgManager() && this.props.app.idm.session.authorization.getUserID() != currentCourse.userID) {
        status = Globals.CourseStatuses.ACCESS_DENIED;
      } else { //Locked!
        status = Globals.CourseStatuses.LOCKED;
      }
    }
    return status;
  }
  _loadState(updateState = false) {
    //
    let initialState = {
      status: this?.state?.status || Globals.CourseStatuses.DEFAULT,
      currentCourse: null, resultsHistory: [], startUncollapsed: false,
      collapsibleCard: (this.props.certificationSpecs.requirementsIDs.length > 3),
      availableKeys: []
    };

    const sortedCertificationProcessCourses = (this.props.certificationProcess?.courses || [])
      .sort((a, b) => b.createdOn - a.createdOn)
      .sort((a, b) => (
        b.state == Globals.Course_State.PENDING ? 1 : 0) - (a.state == Globals.Course_State.PENDING ? 1 : 0
      ))
      .filter((c) => this.props.coursesIDs.includes(c.courseID));

    const latestCourse = sortedCertificationProcessCourses[0] || null;
    this.courseSpecs = this.props.app.sharedCache().getCourseByID(
      latestCourse ? latestCourse.courseID : this.props.coursesIDs[0],
      this.props.certificationSpecs.id,
    );

    //Note: If we have 2 courseSpecs `this.props.coursesIDs` that have different requirementsIDs, this
    //logic might break, not sure how to handle this. Believe we should have this constrain on the ConfigFE.
    //Note2: This logic runs before the constructor initializes the state, we need to be careful when
    //handling state properties and async requests (that's why validateRequirements runs a setState with a callback)
    let isLocked = false;
    if (Array.isArray(this.courseSpecs.requirementsIDs) && this.courseSpecs.requirementsIDs.length > 0) {
      isLocked = !this.props.certificationProcess.courses?.some(p => (this.courseSpecs.requirementsIDs || []).some(reqID =>
        p.courseID == reqID &&
        !p.invalidated &&
//        (p.state == 'PASSED' || p.sessionID) && - this line was added by Rescio to allow the user to see the course even if it's not passed - this was done incorrectly, removing it now on Feb 14, 2024
        p.state == 'PASSED' &&
        (!p.expiryDate || p.expiryDate == -1 || p.expiryDate > Date.now())
      ));

      // checks if the student is enroled in a session even though requirements are not met. This could have been done using session co-enrolment where that case is allowed.
      // if this condition is found, we will unlock the card
      const currentCourse = sortedCertificationProcessCourses.filter(course => this.props.coursesIDs.includes(course.courseID));
      if (isLocked && currentCourse[0]?.sessionID) isLocked = false;

      // here we start our legacy treatment for requirements
      if (isLocked && this.state?.isCourseValid) {
        isLocked = false;
        if (this.state?.detailsVisible === false) this.handleVisibilityToogle();
      } else if (isLocked && (!this.state || this.state.isCourseValid === null)) {
        this._validateRequirements();
      } else console.log('*-*- card _loadState did not call _validateRequirements');
    }
    //Check special cases and determine course card status
    if (isLocked) {
      initialState.status = Globals.CourseStatuses.MISSING_REQUIREMENTS;
    } else if (!latestCourse) {
      initialState.status = Globals.CourseStatuses.PAYMENT_REQUIRED;
      initialState.startUncollapsed = true;
    } else {
      const findCourses = sortedCertificationProcessCourses.filter(course => this.props.coursesIDs.includes(course.courseID));
      initialState.currentCourse = findCourses[0];
    }

    //Check for pending state on course
    if (initialState.currentCourse && initialState.currentCourse.state == Globals.Course_State.PENDING) {
      initialState.startUncollapsed = true;
    }

    //Get all results
    initialState.resultsHistory = sortedCertificationProcessCourses.filter(course => this.props.coursesIDs.includes(course.courseID));
    //State Update
    if (updateState) this.setState(prevState => ({ ...prevState, ...initialState }), () => { if (!isLocked) this._loadCourseSession(); });

    return initialState;
  }


// Private API
async _validateRequirements() {
  const reqsResp = await this.props.app.api.user.getStatusForMultipleRequirements(this.props.user.id, this.courseSpecs.requirementsIDs);
  if (reqsResp.statusCode !== 200) return false;
  
  const validReqs = reqsResp.body.items.filter((requirement) => {
    return (
      requirement.type === 'SUCCESS' &&
      (!requirement.expiresOn || requirement.expiresOn === -1 ||
      requirement.expiresOn >= Date.now())
    );
  });

  let isValidReq;
  if (this.courseSpecs.allowIfAnyRequirementMet) {
    // If allowIfAnyRequirementMet is true, valid if any requirement is met
    isValidReq = validReqs.length > 0;
  } else {
    // If allowIfAnyRequirementMet is false, valid if all requirements are met
    isValidReq = validReqs.length === this.courseSpecs.requirementsIDs.length;
  }

  this.setState({ isCourseValid: isValidReq }, () => this.reloadState());
}

  async _loadCourseSession(setIsLoading = true) {
    if (setIsLoading) this.startLoading();
    //Get user available licenses
    let keysResp = [];
    if (this.props.certificationProcess?.userID && this.courseSpecs) {
      const product = this.props.app.sharedCache().getProductByProductRelatedObject(this.courseSpecs);
      keysResp = await this.props.app.license.key.getAvailableKeys(
        this.props.certificationProcess?.userID, 1, product.id
      );
      keysResp = keysResp.statusCode == 200 ? keysResp.body?.keys || [] : [];
    }
    //Check for session (this will also return attendance and result objects)
    if (this.state.currentCourse?.sessionID) {
      const session = await this._loadSession(this.state.currentCourse?.sessionID, this.state.currentCourse?.userID, this.state.currentCourse);
      if (session) {
        // load on demand cache if its not loaded already
        if (session.venueID) {
          await this.props.app.sharedCache().getVenues();
          await this.props.app.sharedCache().getCities();
        }
        this.setState({ session, isLoading: false, status: this._getCurrentCourseStatus(session), availableKeys: keysResp });
      } else this.stopLoading();
    } else {
      const product = this.props.app.sharedCache().getProductByProductRelatedObject(this.courseSpecs);
      const { EXPIRED, FAILED, INVALIDATED, PAYMENT_REQUIRED, REVOKED} = Globals.CourseStatuses;
      const status = this._getCurrentCourseStatus();
      // Nov 21 - 2023 Removed the auto activation process for products with price 0. Process never gave users the choice to select an in person session
      if ([EXPIRED, FAILED, INVALIDATED, PAYMENT_REQUIRED, REVOKED].includes(status) && product.price == 0) {
        if(this.courseSpecs.courseType === Globals.Course_Types_Keys.EXTERNALREQUIREMENT) {
          console.log('auto activating');
          this._startAutoActivateProcess(); // do it for external requirements, otherwise skip auto activation
        }
      }
      this.setState({ status, availableKeys: keysResp });
    }
    if (setIsLoading) this.stopLoading();
  }
  async _loadSession(sessionID, userID, course) {
    const resp = await this.props.app.classroom.session.getSessionUserData(sessionID, userID, course.id);
    if (resp.statusCode == 200) {
      return {
        ...resp.body, // for compatiblity purposes
        attendances: resp.body?.attendance ? [resp.body?.attendance] : [],
        results: resp.body?.result ? [resp.body?.result] : [],
        enrolments: resp.body?.enrolment ? [resp.body?.enrolment] : [],
      };
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }
  }
  async _launchOnlineCourse(currentCourse, courseSpecs) {
    this.startLoading();
    const resp = await this.props.app.classroom.sessionEnrolment.launchSessionEnrolment(currentCourse.sessionID, currentCourse.userID, currentCourse.id, { callbackURL: encodeURI(window.location) });
    console.debug('Launch resp:', resp);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.urlManager.openExternalPage(resp.body.url);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _checkOnlineCourseResults(currentCourse) {
    this.startLoading();
    const resp = await this.props.app.classroom.sessionEnrolment.checkSessionEnrolmentResults(currentCourse.sessionID, currentCourse.userID, currentCourse.id);
    console.log('Check online result resp:', resp);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Results retrieved with success!');
      this.props.onUpdate();
    } else {
      message.warning('Results are not available yet!');
    } this.stopLoading();
  }
  async _releaseOnlineCourseAccess(currentCourse) {
    this.startLoading();
    const resp = await this.props.app.classroom.sessionEnrolment.releaseSessionEnrolment(currentCourse.sessionID, currentCourse.userID, currentCourse.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Access release with success!');
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _forceCourseCooldownEnd(currentCourse) {
    this.startLoading();
    const resp = await this.props.app.api.course.forceEndCooldown(currentCourse.userID, currentCourse.certProcID, currentCourse.id, { comments: '' });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Cooldown ended with success!');
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async _cancelCourse(currentCourse) {
    this.startLoading();
    const { userID, certProcID, id, sessionID, licenseID } = currentCourse;
    const isOrgMngr = this.props.app.isOrgManager();
    let order = null;
    // get order as admin
    if (this.props.app.isAdmin()) {
      order = await this._adminFindOrder(userID, licenseID);
    } else { // get order as user/orgMngr
      let externalID = isOrgMngr ? this.props.app.urlManager.selectedOrg : userID;
      const licOrderResp = await this.props.app.license.order.getOrdersByExternalID(externalID);
      if (licOrderResp.statusCode != 200 || !licOrderResp.body?.orders) {
        this.props.app.alertController.showAPIErrorAlert(null, licOrderResp);
        this.stopLoading();
        return;
      }
      order = licOrderResp.body.orders.filter(o => o.licenseID == licenseID)[0];
    }
    if (!order && isOrgMngr) {
      this.props.app.alertController.showAPIErrorAlert('Error!', { body: { err: `This license doesn\'t allow for cancelations. Please contact ${this.props.app.sharedCache().getTenantConfig().organizationName} to cancel the enrolment in this course!` } });
      this.stopLoading();
      return;
    } else if (!order || (order.quantity != 1 && !order.isNoCostOrder)) {
      this.props.app.alertController.showAPIErrorAlert('Error!', { body: { err: `Your license either belongs to another user or contains multiple items. Due to that it is not currently possible to cancel your enrolment. Please contact ${this.props.app.sharedCache().getTenantConfig().organizationName} to cancel your participation in this course!` } });
      this.stopLoading();
      return;
    }
    //get cancelation charges
    const resp = await this.props.app.api.course.calcCancelationCharges(userID, certProcID, id, sessionID);
    if (!this._isMounted) {
      this.stopLoading();
      return;
    }
    //get cancelation policy
    const cancelPolicy = this.props.app.sharedCache().getTenantConfig().customer.cancelationPolicies.find(cp => cp.id == this.state.session.cancelationPolicyID);
    if (resp.statusCode == 200) {
      const total = resp.body.cancelationCharge - resp.body.refundAmount;
      if (total > 0) {
        this.stopLoading();
        this.handleChargeModal(order, { ...cancelPolicy, charges: resp.body });
      } else if (total < 0 && !isOrgMngr) {
        this.stopLoading();
        order.value = total * -1;
        this.handleRefundModal(order, { ...cancelPolicy, charges: resp.body });
      } else this.cancelEnrolment();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading();
  }
  async cancelEnrolment() {
    const { userID, certProcID, id, sessionID } = this.state.currentCourse;
    const cancelResp = await this.props.app.api.course.cancelEnrol(userID, certProcID, id, sessionID);
    if (cancelResp.statusCode == 200) {
      message.success('Unregistered from course session with success!');
      this.props.onUpdate();
    } else this.props.app.alertController.showAPIErrorAlert(null, cancelResp);
  }
  async _adminFindOrder(userID, licenseID) {
    //TODO: THIS NEEDS TO BE BETTER TESTED TO CORRECTLY ASSUME ORG MNGR ROLE
    let order = null;
    // user order
    const licOrderResp = await this.props.app.license.order.getOrdersByExternalID(userID);
    if (licOrderResp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, licOrderResp);
      return;
    }
    order = licOrderResp.body?.orders?.filter(o => o.licenseID == licenseID)[0];
    if (order) return order;
    // org order
    const user = this.props.app.sharedCache().getProgramUser();
    for (const orgID of user?.memberOf || []) {
      const orgLicOrderResp = await this.props.app.license.order.getOrdersByExternalID(orgID);
      if (orgLicOrderResp.statusCode != 200) {
        this.props.app.alertController.showAPIErrorAlert(null, orgLicOrderResp);
        return;
      }
      order = orgLicOrderResp.body?.orders?.filter(o => o.licenseID == licenseID)[0];
      if (order) return order;
    }
  }
  async _downloadCertificate() {
    const { currentCourse } = this.state;
    this.startLoading();
    const resp = await this.props.app.api.certification.courseCertificatePrint(currentCourse.userID, currentCourse.certProcID, currentCourse.id);
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, 'file', 'pdf');
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
    }
    this.stopLoading();
  }
  async _onRetake(currentCourse) {
    this.startLoading();
    const {userID, certProcID, id} = currentCourse;
    const retakeResp = await this.props.app.api.course.retake(userID, certProcID, id);
     if (retakeResp.statusCode === 200) {
      message.success('Updated course status with success!');
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, retakeResp);
    } this.stopLoading();
  }
  async _onRequestApproval() {
    this.startLoading();
    const body = {
      submissionItems: this.state.currentCourse.submissionItems,
      type: Globals.PendingApprovalType.COURSE_FILE_UPLOAD,
    };
    //Submit
    const resp = await this.props.app.api.course.submit(
        body,
        this.props.user.id,
        this.props.certificationProcess.id,
        this.state.currentCourse.id,
      );
    if (resp.statusCode == 200) {
      message.success('Approval items submitted with success!');
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }

  async _onAdminRevoke(currentCourse) {
    this.startLoading();
    const { userID, certProcID, id } = currentCourse;
    const revokeResp = await this.props.app.api.course.revoke( userID, certProcID, id );
     if (revokeResp.statusCode === 200) {
      message.success('Updated course status with success!');
      this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, revokeResp);
    } this.stopLoading();
  }
  /* Auto activate feature */
  async _startAutoActivateProcess() {
    this.startLoading();
    /* Select session */
    const sessionID = await this._autoSelectSession();
    if (!sessionID) return;
    /* Get license */
    const license = await this._getFreeLicense();
    if (!license) return;
    /* Redeem */
    if (!await this._redeemLicense(license, sessionID)) return;
    // Ask for update
    this.props.onUpdate();
  }
  async _autoSelectSession() {
    //Make request
    const sessionsResp = await this.props.app.classroom.session.getSessionList(Date.now(), Utils.timestampAfterYears(1), this.courseSpecs.id);
    if (!this._isMounted) return;
    //Response
    if (sessionsResp.statusCode == 200 && sessionsResp.body) {
      const { sessions, calendars } = sessionsResp.body;
      //Filter for usable sessions
      const usableSessions = sessions.filter((session) => {
        return !(session.state != Globals.Session_State.AVAILABLE ||
                (session.capacity > 0 && session.capacity - (session.enrolmentCount || 0) - session.reservedCapacity <= 0));
      });
      const onlineCount = (usableSessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []).length;
      const schedulable = (usableSessions?.filter((s) => (s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT)) || []).length;
      console.log(`online count ${onlineCount} schedulable ${schedulable}`);
      //if (onlineCount != 0 && schedulable == 0) {
      if (onlineCount != 0) {
        const onlineSessions = (usableSessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []);
        if (onlineSessions.length == 1) return onlineSessions[0].id;
        else if (onlineSessions.length > 1) {
          // something wrong, admin created more than 1 online session and that is not allowed
          this.props.app.alertController.showAPIErrorAlert('Error!', { body: { err: 'Please contact the administrator, multiple sessions available !' } });
          return;
        }
      }
      this.props.app.alertController.showAPIErrorAlert('Error!', { body: { err: 'Unable to find sessions to automatically enroll.' } });
    } else this.props.app.alertController.showAPIErrorAlert('Error!', sessionsResp);
  }
  async _getFreeLicense() {
    //aux
    const product = this.props.app.sharedCache().getProductByProductRelatedObject(this.courseSpecs);
    const provider = this.props.app.license.order.getProviderFromProduct(product);
    const user = this.props.user;
    // begin
    const paymentResp = await this.props.app.license.order.beginOrder({
      productID: product.id,
      taxPercentage: 0, callbackURL: window.location.href, quantity: 1,
      shouldUseBulkPricing: false, unitValue: 0, voucherID: null,
      user: {
        id: user.id, firstName: user.firstName, lastName: user.lastName,
        email: user.email, referrerName: user.referredByName
      },
    }, provider);
    if (!this._isMounted) return;
    console.debug('Order begin resp:', paymentResp);
    if (paymentResp.statusCode != 200 || !paymentResp.body || !paymentResp.body.orderID) {
      this.props.app.alertController.showAPIErrorAlert('Error!', paymentResp);
      return;
    }
    // complete
    const completePaymentResp = await this.props.app.license.order.completeOrder({
      orderID: paymentResp.body.orderID + '', confirmationID: null,
      user: { id: user.id }, doNotSendEmail: true, autoRedeem: true
    }, provider);
    if (!this._isMounted) return;
    console.debug('Order complete resp:', completePaymentResp);
    if (completePaymentResp.statusCode != 200 || !completePaymentResp.body || !completePaymentResp.body.activationKeys) {
      this.props.app.alertController.showAPIErrorAlert('Error!', completePaymentResp);
      return;
    }
    return completePaymentResp.body.activationKeys[0];
  }
  async _redeemLicense(licenseKey, sessionID) {
    const user = this.props.user;
    const redeemResp = await this.props.app.api.certification.redeemLicense({
      userID: user.id,
      certID: this.props.certificationProcess.id,
      courseID: this.courseSpecs.id,
      activationCode: licenseKey,
      sessionID,
    });
    if (!this._isMounted) return;
    if (redeemResp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Error!', redeemResp);
      return;
    }
    return true;
  }

  //Private animations
  _renderAnimationContext(child, key) {
    //Dont do it on download tab
    if (key == Globals.CourseTabCourseCardTabs.DOWNLOAD) return child;
    if (!this.state.collapsibleCard) return child;
    /* animation */
    const refThis = this;
    function animateEnter(node, done) {
      let ok = false;
      if (!node) { done(); return; }
      if (!refThis.animationControllerCollapsed) { done(); return; }
      // if (node.style.display == 'block') return;
      function complete() {
        if (ok) return;
        ok = 1;
        done();
        refThis.animationControllerCollapsed = false;
      }
      node.style.display = 'none';
      velocity(node, 'slideDown', { duration: 500, easing: 'easeIn', complete });
      return {
        stop() {
          velocity(node, 'finish');
          complete();
        }
      };
    };
    function animateLeave(node, done) {
      let ok = false;
      if (!node) { done(); return; }
      function complete() {
        if (ok) return;
        ok = 1;
        done();
        refThis.animationControllerCollapsed = true;
      }
      //
      node.style.display = 'block';
      velocity(node, 'slideUp', { duration: 500, easing: 'easeOut', complete });
      return {
        stop() {
          velocity(node, 'finish');
          // velocity complete is async
          complete();
        }
      };
    };
    //
    const anim = { enter: animateEnter, leave: animateLeave };
    return (<Animate key={key} component="" showProp="collapsed" animation={anim}>{child}</Animate>);
  }
}
