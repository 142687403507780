import React, { Component } from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import { Row, Col, Button, Radio, DatePicker, Typography } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, SwapRightOutlined } from '@ant-design/icons';
import { MdViewWeek } from 'react-icons/md';
import { CgViewMonth, CgViewDay } from 'react-icons/cg';

import Globals from "../../config/Globals";

import WhiteBox from "./WhiteBox";

export default class CommonSessionsDateRangeSelector extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //Defaults
    const dateType = 1;
    const fromRawDate = (this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_From) ? moment(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_From), Globals.DefaultDateFormat) : moment());
    const fromDate = (fromRawDate.isValid() ? fromRawDate : moment()).clone().startOf((dateType == 1 ? 'week' : 'month')).startOf('day');
    const toRawDate = (this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_To) ? moment(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_To), Globals.DefaultDateFormat) : null);
    this.state = {
      from: fromDate, dateType,
      to: (this.props.anyRangeMode && toRawDate && toRawDate.isValid() ? toRawDate.clone() : (fromRawDate.isValid() ? fromRawDate : moment()).clone().endOf((dateType == 1 ? 'week' : 'month')).endOf('day')),
    };
  }
  //Life cycle
  componentDidMount() { this._updateDelegate(); }

  //actions
  handleDateTypeChange(newVal) {
    if (newVal.target.value == 1) {
      const currentDate = moment().startOf('week').startOf('day');
      this.setState({
        dateType: newVal.target.value,
        from: currentDate,
        to: currentDate.clone().endOf('week').endOf('day')
      }, this._updateDelegate);
    } else if (newVal.target.value == 2) {
      const currentDate = moment().startOf('month').startOf('day');
      this.setState({
        dateType: newVal.target.value,
        from: currentDate,
        to: currentDate.clone().endOf('month').endOf('day')
      }, this._updateDelegate);
    } else if (newVal.target.value == 3) {
      const currentDate = moment().startOf('day');
      this.setState({
        dateType: newVal.target.value,
        from: currentDate,
        to: currentDate
      }, this._updateDelegate);
    }
  }

  handleDateStepChange(diff) {
    let from;
    if (this.state.dateType === 1) {
      from = moment(this.state.from, Globals.DefaultDateFormat).add(diff, 'weeks');
      const to = moment(this.state.to, Globals.DefaultDateFormat).add(diff, 'weeks');
      this.setState({
        from: from.startOf('week').startOf('day'),
        to: to.endOf('week').endOf('day')
      }, this._updateDelegate);
    } else if (this.state.dateType === 2) {
      from = moment(this.state.from, Globals.DefaultDateFormat).add(diff, 'months');
      const to = moment(this.state.to, Globals.DefaultDateFormat).add(diff, 'months');
      this.setState({
        from: from.startOf('month').startOf('day'),
        to: to.endOf('month').endOf('day')
      }, this._updateDelegate);
    } else {
      from = moment(this.state.from, Globals.DefaultDateFormat).add(diff, 'days');
      this.setState({
        from: moment(from.startOf('day').valueOf()),
        to: moment(from.endOf('day').valueOf())
      }, this._updateDelegate);
    }
  }
  handleRangeDataChange(value) {
    if (this.state.dateType == 1) {
      this.setState({ from: value.startOf('week').startOf('day'), to: value.clone().endOf('week').endOf('day') }, this._updateDelegate);
    } else if (this.state.dateType == 2) {
      this.setState({ from: value.startOf('month').startOf('day'), to: value.clone().endOf('month').endOf('day') }, this._updateDelegate);
    } else {
      this.setState({ from: moment(value.startOf('day').valueOf()), to: moment(value.endOf('day').valueOf()) }, this._updateDelegate);
    }
  }

  //
  render() {
    return (
      <WhiteBox>
        <Row type="flex" justify="center" align='middle'>
         {!this.props.anyRangeMode && <Col> <Button type='link' onClick={() => this.handleDateStepChange(-1)}><CaretLeftOutlined style={{ color: 'grey', fontSize: '25px' }} /></Button> </Col>}
          <Col>
            {this.state.dateType === 1 && this._renderWeeklyFilter()}
            {this.state.dateType === 2 && this._renderMonthlyFilter()}
            {this.state.dateType === 3 && this._renderDailyFilter()}
          </Col>
          {!this.props.anyRangeMode && <Col> <Button type='link' onClick={() => this.handleDateStepChange(1)}><CaretRightOutlined style={{ color: 'grey', fontSize: '25px' }} /></Button> </Col>}
        </Row>
        {!this.props.anyRangeMode && <Row type="flex" justify="end" style={{ marginTop: '-31px' }}>
          <Col>
            <Radio.Group onChange={this.handleDateTypeChange} value={this.state.dateType}>
              {this.props.daily && <Radio.Button value={3}><CgViewDay style={{ marginRight: '10px' }} />Daily</Radio.Button>}
              <Radio.Button value={1}><MdViewWeek style={{ marginRight: '10px' }} /> Weekly</Radio.Button>
              <Radio.Button value={2}><CgViewMonth style={{ marginRight: '10px' }} />Monthly</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>}
      </WhiteBox>
    );
  }

  /* private renders */
  _renderWeeklyFilter() {
    return (
      <DatePicker picker='week' onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={this.state.from} suffixIcon={
        <div style={{ width: '110px' }}>
          <SwapRightOutlined style={{ color: 'black', display: 'inline', marginRight: '10px' }} />
          <Typography.Text>
            {moment(this.state.to).format(Globals.DefaultDateFormat)}
          </Typography.Text>
        </div>} />
    );
  }
  _renderMonthlyFilter() {
    return (
      <DatePicker picker='month' onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={this.state.from} suffixIcon={
        <div style={{ width: '110px' }}>
          <SwapRightOutlined style={{ color: 'black', display: 'inline', marginRight: '10px' }} />
          <Typography.Text>
            {moment(this.state.to).format(Globals.DefaultDateFormat)}
          </Typography.Text>
        </div>} />
    );
  }

  _renderDailyFilter() {
    return (
      <DatePicker onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={this.state.from}/>
    );
  }

  /* private URL */
  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_From, moment(this.state?.from).format(Globals.DefaultDateFormat));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_To, moment(this.state?.to).format(Globals.DefaultDateFormat));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Type, this.state?.dateType + '');
  }
  _updateDelegate() {
    this._reloadURLParams();
    if (this.props.onDateChange) this.props.onDateChange(this.state.from.toDate().getTime(), this.state.to.toDate().getTime(), this.state.dateType);
  }
}
