import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Divider, Row, Col, Button, Select } from 'antd';
import moment from 'moment';
//
import Utils from '../../../../components/Utils';

//
import CommonDailySessionSelectionView from '../../Calendar/CommonDailySessionSelectionView';
import CommonWeeklySessionSelectionView from '../../Calendar/CommonWeeklySessionSelectionView';
import CommonMonthlySessionsView from '../../Calendar/CommonMonthlySessionsView';
import CommonSessionsDateRangeSelector from '../../CommonSessionsDateRangeSelector';


//props are: app, onCancel, onSelect, sessions, calendars,
export default class CommonCoursePurchaseModalSubStep_CalendarSessionSelection extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //Find earliest session and use as start date!
    const earliestSessionDate = this.props.sessions?.reduce((acc, session) => {
      if (session && session.startDate) {
        const earliestOnSession = session.startDate.sort((a, b) => a - b)[0];
        if (earliestOnSession < acc) return earliestOnSession;
      } return acc;
    }, Infinity);
    this.state = {
      from: moment(new Date(earliestSessionDate == Infinity ? Date.now() : earliestSessionDate)).startOf('week').startOf('day'),
      to: moment().clone().endOf('week').endOf('day'),
      dateType: 1,
      isLoading: false,
      sessions: [], cities: [],
      filteredSessions: this.props.sessions,
      selectedCities: [],
    };
  }
  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    this.setState({ isLoading: true });
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.setState({ isLoading: false });
  }
  //Filter
  handleFilterCityChange(cities) {
    this.setState({ selectedCities: cities });
  }

  //Actions
  handleDateChange(from, to, dateType) {
    this.setState({ from, to, dateType }, this.fetchData);
  }
  handleRangeDataChange(value) { this.setState({ from: value.startOf('week').startOf('day'), to: value.clone().endOf('week').endOf('day') }); }
  //UI
  render() {
    return (
      <>
        <Divider orientation='left'>Select a session:</Divider>
        {this._renderDatePicker()}
        {this._renderFilters()}
        {this._renderSessionView()}
        {this._renderButtonsFooter()}
      </>
    );
  }

  /* Private UI */
  _renderDatePicker() {
    return (
      <>
        <CommonSessionsDateRangeSelector
          anyRangeMode={false}
          daily={true}
          app={this.props.app}
          onDateChange={this.handleDateChange.bind(this)}
          onTimeEntryAdded={this._fetchData}
          onTimeEntryEdited={this._fetchData}
          {...Utils.propagateRef(this, 'sessionsDateRangeSelector')}
        />
      </>
    );
  }
  _renderFilters() {
    return (
      <Layout.Content>
        <Row type='flex' justify='center'>
          <Col style={{ borderRadius: '2*6px', padding: '10px 0 10px 0', marginRight: '20px' }}>
            City: <Select mode="tags" placeholder="All Cities" style={{ marginLeft: '5px', minWidth: '300px', marginRight: '0px' }} allowClear optionFilterProp="label" onChange={this.handleFilterCityChange.bind(this)} value={this.state.selectedCities}>
              {this.state.cities.map((c) => {
                return <Select.Option value={c.id} key={c.id}>{c.name} ({c.province})</Select.Option>
              })}
              </Select>
          </Col>
        </Row>
      </Layout.Content>
    );
  }

  _renderSessionView() {
    if (this.state.dateType === 1) {
     return (
      <CommonWeeklySessionSelectionView app={this.props.app} sessions={this._getFilteredSessions()} calendars={this.props.calendars}
      onSelect={(sessionID) => this.props.onSelect(sessionID)} from={this.state.from ? moment(this.state.from) : moment()}
      to={this.state.to ? moment(this.state.to) : moment()}/>
     )
    } else if (this.state.dateType === 2) {
      return (
        <CommonMonthlySessionsView app={this.props.app} sessions={this._getFilteredSessions()} calendars={this.state.calendars}
        from={this.state.from ? moment(this.state.from) : moment()}
        to={this.state.to ? moment(this.state.to) : moment()} onSelect={(sessionID) => this.props.onSelect(sessionID)}/>
      )
    } else {
      return (
        <CommonDailySessionSelectionView app={this.props.app} sessions={this._getFilteredSessions()} calendars={this.props.calendars}
        onSelect={(sessionID) => this.props.onSelect(sessionID)} from={this.state.from ? moment(this.state.from) : moment()}
        />
      )
    }
  }

  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button form="summary" htmlType="submit" key="submit" type="secondary" onClick={this.props.onCancel}> Cancel </Button>
      </Row>
    );
  }
  _getFilteredSessions() {
    return this.props.sessions.filter((session) => {
      const venue = this.props.app.sharedCache().getVenueByID(session.venueID);
      return (
        (!this.state.selectedCities?.length || this.state.selectedCities.includes(venue?.cityID))
      );
    });
  }
  async _loadAll() {
    const resp = await Promise.all([
      this.props.app.sharedCache().getCities(),
      this.props.app.sharedCache().getVenues(),
    ]);
    if (!resp[0]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    return { cities: resp[0] };
  }
}

